import { models } from '@ynomia/core';
import client from './Client';

export async function fetchModels(
  tenant: string,
  projectCode: string,
  options?: {
    activeOnly?: boolean,
    includeMapping?: boolean,
  },
): Promise<models.ModelResponse[]> {
  const result = await client.server.get(
    `/scratch/project/${tenant}/${projectCode}/models`,
    {
      params: {
        activeOnly: options?.activeOnly ? '1' : '0',
        includeMapping: options?.includeMapping ? '1' : '0',
      },
    },
  );
  return result.data.value;
}

export async function patchModel(
  tenant: string,
  projectCode: string,
  id: string,
  model: Partial<models.Model>,
) {
  const result = await client.server.patch(
    `/scratch/project/${tenant}/${projectCode}/model/${id}`,
    model,
  );
  return result.data.value;
}

export async function createModel(
  tenant: string,
  projectCode: string,
  model: Partial<models.Model>,
): Promise<Partial<models.Model>> {
  const result = await client.server.post(
    `/scratch/project/${tenant}/${projectCode}/model`,
    model,
  );
  return result.data.value;
}
