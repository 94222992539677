export const calculateLoadingPercentage = (
  modelByKeys: { [key: string]: any },
  models: Array<string>,
): number => {
  const sum = models.reduce(
    (accumulator, key) => accumulator + (modelByKeys[key]?.loading || 0),
    0,
  );

  return Math.trunc(sum / models.length) || 0;
};
