/* eslint-disable max-len */
/**
 * State extractors (for internal use).
 */

import { ContextStores } from '../context';

const map = {
  scratchProjectCode: (state: ContextStores) => state.clientCacheState?.clientCache?.bootstrap?.project?.metadata.scratchProjectCode,
  projectId: (state: ContextStores) => state.clientCacheState?.clientCache?.bootstrap?.project?.metadata.id,
  projectName: (state: ContextStores) => state.clientCacheState?.clientCache?.bootstrap?.project?.metadata.name,
  tenant: (state: ContextStores) => state.clientCacheState?.clientCache?.bootstrap?.project?.metadata.tenant,
  userInfo: (state: ContextStores) => state.clientCacheState?.clientCache?.auth.userInfo,
  projects: (state: ContextStores) => state.clientCacheState?.clientCache?.bootstrap?.projects,
  project: (state: ContextStores) => state.clientCacheState?.clientCache?.bootstrap?.project,
  reports: (state: ContextStores) => state.clientCacheState?.clientCache?.bootstrap?.project?.reports_v2,
  features: (state: ContextStores) => state.clientCacheState?.clientCache?.bootstrap?.project?.features,
  announcement: (state: ContextStores) => state.clientCacheState?.clientCache?.bootstrap?.project?.features.announcement,
  managedRoles: (state: ContextStores) => state.clientCacheState?.clientCache?.bootstrap?.project?.features.user_management.managed_roles,
  models: (state: ContextStores) => state.digitalTwinState.models,
  menu: (state: ContextStores) => state.clientCacheState?.clientCache?.bootstrap?.project?.menu,
  dateFormat: (state: ContextStores) => state.clientCacheState.clientCache?.bootstrap?.project?.calendar.dateFormat,
  timezone: (state: ContextStores) => state.clientCacheState.clientCache?.bootstrap?.project?.calendar.timezone?.timezone,
  assetTypes: (state: ContextStores) => state.clientCacheState?.clientCache?.bootstrap?.project?.asset_types.types,
  templates: (state: ContextStores) => state.clientCacheState?.clientCache?.bootstrap?.project?.site_templates?.templates,
  roleTypes: (state: ContextStores) => state.clientCacheState?.clientCache?.bootstrap?.project?.role_types,
  layerTypes: (state: ContextStores) => state.clientCacheState?.clientCache?.bootstrap?.project?.layer_types.layer_types,
  projectUserList: (state: ContextStores) => state.clientCacheState?.clientCache?.bootstrap?.project?.users,
  layersKeyedById: (state: ContextStores) => state.layerState?.layersKeyedById,
  assetManagerKeyedById: (state: ContextStores) => state.assetManagerState,
  assetManagerId: (state: ContextStores) => state.featureState.assetManagerId,
  assetsKeyedById: (state: ContextStores) => state.assetState?.assetsKeyedById || {},
  lastFetchStartTime: (state: ContextStores) => state.assetState?.lastFetchStartTime || null,
  accessRulesKeyedById: (state: ContextStores) => state.accessRuleState?.accessRulesKeyedById,
  visibilityFiltersKeyedById: (state: ContextStores) => state.visibilityFilterState?.visibilityFiltersKeyedById,
  searchAccessRules: (state: ContextStores) => state.accessRuleState?.searchAccessRules,
  filtersAccessRulesTable: (state: ContextStores) => state.accessRuleState?.filtersAccessRulesTable,
  plannedUpdatedAsset: (state: ContextStores) => state.assetUpdateState?.plannedUpdatedAsset,
  assetUpdateMode: (state: ContextStores) => state.assetUpdateState?.assetUpdateMode,
  plannedUpdatedStatus: (state: ContextStores) => state.statusUpdateState?.plannedUpdatedStatus,
  filtersAssetUpdate: (state: ContextStores) => state.assetUpdateState?.filtersAssetUpdate,
  filtersAssetUpdateTable: (state: ContextStores) => state.assetUpdateState?.filtersAssetUpdateTable,
  assetFileName: (state: ContextStores) => state.assetUpdateState?.fileName,
  assetImportTime: (state: ContextStores) => state.assetUpdateState?.importTime,
  statusFileName: (state: ContextStores) => state.statusUpdateState?.fileName,
  statusImportTime: (state: ContextStores) => state.statusUpdateState?.importTime,
  devicesKeyedById: (state: ContextStores) => state.deviceState.devicesKeyedById,
  plansKeyedById: (state: ContextStores) => state.planState.plansKeyedById,
  assetDetailsAssetId: (state: ContextStores) => state.layoutState?.assetDetailsAssetId,
  presenterMode: (state: ContextStores) => state.layoutState.presenterMode,
};

export default map;
