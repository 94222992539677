import {
  Col, Divider, Image, Row,
} from 'antd';
import { Field } from '@ynomia/core/dist/blueprint';
import React from 'react';
import { compileExpression } from 'filtrex';
import { filtrexHelpers } from '@ynomia/core/dist/utils';
import { displayDateInProjectTime } from '../../../utils';
import { ImagePreview } from '..';
import { Asset } from '../../../config/types/assets';
import styles from './styles.module.less';
import { SearchHighlight } from '../../connected';

export interface Props {
  schema: Field[];
  values: { [fieldId: string]: any };
  asset?: Asset;
  display?: {
    gutter?: [number, number];
    labelColSpan?: number;
    valueColSpan?: number;
    valueColFlex?: string;
  };
}

/**
 * Use this component wherever you want to display (read only) the value of Ynomia Fields,
 * such as showing asset properties, observation values and anything else that uses our
 * standardised `Field` schema.
 *
 * @param {Props} props
 * @param {Field[]} props.schema: The schema of the field set that should be displayed.
 * @param {object} props.value: Raw field values (corresponding to the field schema).
 * @param {Asset?} props.asset: If the fields somehow relate to an asset, pass this in.
 * This can then be used by Filtrex props for more advanced showing/hiding of fields.
 * @param {object?} props.display
 * @param {[number, number]?} props.display.gutter: Padding for the row gutter.
 * @param {number?} props.display.labelColSpan: How wide should the label column be?
 * @param {number?} props.display.valueColSpan: How wide should the value column be?
 * @param {string?} props.display.valueColFlex: Custom `flex` value for the value column.
 */
const FieldValues: React.FC<Props> = ({
  schema,
  values,
  asset,
  display,
}) => {
  const renderFieldValue = (field: Field) => {
    const value = values[field.id];
    if (value === undefined || value === null || value === '') return <span />;
    let formattedValue = '';
    switch (field.fieldType) {
      case 'text':
        if (field.properties?.url && filtrexHelpers.isUrl(value)) {
          formattedValue = value;
          if (value.length > 44) {
            formattedValue = (
              `${value.substr(0, 22)}...${value.substr(value.length - 22, value.length)}`
            );
          }
          return (
            <a
              href={value}
              target="_blank"
              className={styles.link}
              rel="noreferrer"
            >
              <SearchHighlight text={formattedValue} />
            </a>
          );
        }
        return <SearchHighlight text={value} />;
      case 'photo':
        return (
          <Image.PreviewGroup>
            <div style={{ display: 'flex' }}>
              {value?.map(
                (src: string) => <ImagePreview key={src} src={src} useYnomiaAccessToken />,
              )}
            </div>
          </Image.PreviewGroup>
        );
      case 'date':
        return (
          <SearchHighlight
            text={displayDateInProjectTime(value, 'MMM DD YYYY')}
          />
        );
      case 'time':
        return (
          <SearchHighlight
            text={displayDateInProjectTime(value, 'MMM DD YYYY [at] hh:mma')}
          />
        );
      case 'datetime':
        return (
          <SearchHighlight
            text={displayDateInProjectTime(value, 'MMM DD YYYY [at] hh:mma')}
          />
        );
      case 'picklist':
        formattedValue = '';
        if (field.properties?.multi && Array.isArray(value)) {
          const labels = value.map(v => (
            field.properties?.options?.find((o: any) => o.value === v)?.label || 'Unknown'
          ));
          formattedValue = labels.join(', ');
        } else {
          formattedValue = (
            field.properties?.options?.find((o: any) => o.value === value)?.label || 'Unknown'
          );
        }

        return <SearchHighlight text={formattedValue} />;
      case 'segmented_picklist':
        formattedValue = field.properties?.columns
          ?.map(col => col?.options?.find?.(o => o.value === value?.[col.id])?.label)
          ?.filter(label => label !== undefined)
          ?.join(field.properties?.displayDelimiter || ', ')
          || '';
        return <SearchHighlight text={formattedValue} />;
      case 'checkbox':
        return <span>{value ? 'Yes' : 'No'}</span>;
      default:
        return (
          <SearchHighlight
            text={typeof value === 'string' ? value : JSON.stringify(value)}
          />
        );
    }
  };

  const filteredFields = schema.filter(({ show, hidden }) => {
    if (hidden) return false;
    if (!show) return true;

    const showFilter = compileExpression(
      show,
      {
        extraFunctions: filtrexHelpers,
      },
    );

    return showFilter({ values, element: asset || {} });
  });

  return (
    <span>
      {filteredFields.map(field => (field.fieldType === 'divider'
        ? <Divider dashed key={field.id} />
        : (
          <Row gutter={display?.gutter} key={field.id} className={styles.fieldsContainer}>
            <Col span={display?.labelColSpan}>
              <span>{field.label}</span>
            </Col>
            <Col
              span={display?.valueColSpan}
              flex={display?.valueColFlex}
              style={{ textAlign: 'right' }}
            >
              {renderFieldValue(field)}
            </Col>
          </Row>
        )))}
    </span>
  );
};

export default FieldValues;
