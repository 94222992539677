/* eslint-disable @typescript-eslint/naming-convention */
import { ID } from '@ynomia/core/dist/blueprint';

export enum SYSTEM_TYPE {
  CREATE_ASSET_OBSERVATION = 'ynomia::system::create_asset_observation',
  STATUS_UPDATE_OBSERVATION = 'ynomia::system::status_update_observation',
  AUTOMATIC_STATUS_UPDATE_OBSERVATION = 'ynomia::system::automatic_status_update_observation',
  AUTOFILL_STATUS_UPDATE_OBSERVATION = 'ynomia::system::autofill_status_update_observation',
  DEVICE_ASSIGNED_OBSERVATION = 'ynomia::system::device_assigned_observation',
  DEVICE_UNASSIGNED_OBSERVATION = 'ynomia::system::device_unassigned_observation',
  ELEMENT_SWAPPED_OBSERVATION = 'ynomia::system::element_swapped_observation',
  DESTINATION_UPDATE = 'ynomia::system::destination_update_observation',
  ADD_TO_PARENT_OBSERVATION = 'ynomia::system::add_to_parent_observation',
  REMOVE_FROM_PARENT_OBSERVATION = 'ynomia::system::remove_from_parent_observation',
  UPDATE_CHILDREN_OBSERVATION = 'ynomia::system::update_children_observation',
}

export type RawObservationRecord = {
  _id: string;
  created: string;
  date?: string;
  isBackdated?: boolean;
  batchID?: string;
  from?: string;
  monitorableID: string;
  deviceID?: string;
  deviceUDID?: string;
  source: string;
  order: number;
  to?: string;
  type: SYSTEM_TYPE | string;
  userID?: string;
  isOutOfSequence?: boolean;
  isCurrentStatus?: boolean;
  fields?: { [key: string]: any };
};

export type Asset = ID & {
  ready: boolean; // Custom mobile property: has the asset reached the backend?
  isFetching?: boolean; // Custom mobile property: is the asset data currently being refreshed?
  trackable?: {
    // No idea why this is an array. But it is.
    tracking: Array<{
      alt: number;
      lat: string; // Why are these not numbers
      lon: string; // Why is this not `lng`.
      layers: Array<string>;
      rtLayers?: Array<string>;
      updatedAt: Date;
    }>;
  };
  observations?: Array<RawObservationRecord>;
  isArchived?: boolean;
};

export type TwinData = {
  lastFetched: Date | null
  hexColorsKeyedByTwinId: { [twinID: string]: string }
};

export type AssetTableColumn = {
  key: string
  column_order: {
    full_view: number
    small_view: number
  }
  title: string
  filter_enabled: boolean
  sort_enabled: boolean
  custom?: string
  layerType?: string
  parentSlot?: {
    label: string
    type: string
  }
  fixed?: string,
};

export type ObservationAction = 'NONE' | 'OPEN_ASSET_TYPE_OBSERVATION' | 'VIEW_OBSERVATION' |
'VIEW_BOOKING' | 'VIEW_SWAP' | 'VIEW_DESTINATION_UPDATE' | 'VIEW_CHILDREN_SLOT_OBSERVATION';

export type ObservationSummaryConfig = {
  title: string;
  observationTypes: Array<string>
};

export type StatusSummaryConfig = {
  hide_cumulative: boolean
};

export type FieldSummariesConfig = {
  title: string,
  key: string,
  generateOptionsFromAssetList?: boolean,
  customIcon?: string,
}[];

export type ObservationListItem = {
  observation_id?: string;
  observation_raw?: RawObservationRecord;
  timestamp: string;
  server_timestamp?: Date | string | number;
  timestamp_human_readable: string;
  title: string;
  description?: string;
  statusColor: string;
  icon: {
    name: string;
    type: string;
  };
  action: string,
  canDelete: boolean;
  fontColor?: string | null;
  tooltip?: string;
  isAutoFill?: boolean;
  canEditDate?: boolean;
  excludeTime?: boolean;
};
