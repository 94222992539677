import React, {
  createContext, FC, useContext, useReducer,
} from 'react';
import { ContextDispatch, PageLayout } from '../config/types';
import { INITIAL_DONT_SHOW_PRESENTER_SETTING } from '../config/constants';
import LayoutReducer from '../reducers/layout';

const DEFAULT_PRESENT_MODE_COMPONENTS = [
  'plan',
  'digitalTwin',
  'recentStatusChanges',
  'fieldSummaries',
  'observationSummary',
  'assetStatusSummary',
  'layerSummary',
];

export type LayoutStateType = {
  pageLayout: PageLayout
  summariesCollapsed: boolean
  assetDetailsAssetId: string | null
  assetDetailsLayout: PageLayout
  presenterMode: boolean
  presenterModeComponents: Array<string> | null
  dontShowPresenterSetting: boolean
};

const urlParams = new URLSearchParams(window.location.search);

const defaultParams = {
  pageLayout: 'assetTable',
  summariesCollapsed: 'false',
  assetDetailsLayout: 'assetTable',
  presenterMode: 'false',
  presenterModeComponents: encodeURIComponent(JSON.stringify(DEFAULT_PRESENT_MODE_COMPONENTS)),
};

const getLayoutParam = (key: string) => urlParams.get(key) ?? defaultParams[key];

const setLayoutPram = (url: URL, key: string, value: any) => ((value === defaultParams[key])
  ? url.searchParams.delete(key)
  : url.searchParams.set(key, value));

const initialState: LayoutStateType = {
  pageLayout: getLayoutParam('pageLayout') as PageLayout,
  summariesCollapsed: (getLayoutParam('summariesCollapsed') === 'true'),
  assetDetailsAssetId: null,
  assetDetailsLayout: getLayoutParam('assetDetailsLayout'),
  presenterMode: getLayoutParam('presenterMode') === 'true',
  presenterModeComponents: JSON.parse(
    decodeURIComponent(getLayoutParam('presenterModeComponents')),
  ),
  dontShowPresenterSetting: INITIAL_DONT_SHOW_PRESENTER_SETTING,
};

const setLayoutSearchParams = (state: LayoutStateType) => {
  const url = new URL(window.location.href);
  setLayoutPram(url, 'pageLayout', state.pageLayout);
  setLayoutPram(url, 'summariesCollapsed', state.summariesCollapsed.toString());
  setLayoutPram(url, 'assetDetailsLayout', state.assetDetailsLayout.toString());
  setLayoutPram(url, 'presenterMode', state.presenterMode.toString());
  setLayoutPram(
    url,
    'presenterModeComponents',
    encodeURIComponent(JSON.stringify(
      state.presenterModeComponents ?? DEFAULT_PRESENT_MODE_COMPONENTS,
    )),
  );
  window.history.pushState(null, '', url);
};

export const LayoutContext = createContext({
  state: initialState,
  dispatch: (() => { }) as ContextDispatch,
});

export const useLayout = (): [LayoutStateType, ContextDispatch] => {
  const { state, dispatch } = useContext(LayoutContext);
  return [state, dispatch];
};

export const LayoutProvider:FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [state, dispatch] = useReducer(LayoutReducer, initialState);

  setLayoutSearchParams(state);
  return (
    <LayoutContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
