import {
  AssetType, ObservationSchema,
} from '@ynomia/core/dist/blueprint';
import { createSelector } from 'reselect';
import {
  getAssetManagerFilters,
  getAssetTypes,
} from '.';

import { DEFAULT_STATUS_COLOR } from '../config/constants';

export const getSelectedAssetType = createSelector(
  [getAssetManagerFilters, getAssetTypes],
  (assetManagerFilters, assetTypes) => {
    const { typeFilter } = assetManagerFilters;
    return (assetTypes.find(({ type }) => type === typeFilter) || assetTypes[0]) as AssetType;
  },
);

export const getObservationTypes = createSelector(
  [getSelectedAssetType],
  (selectedAssetType) => {
    const observationTypes = selectedAssetType?.observations || [];
    return observationTypes;
  },
);

export const getObservationTypesKeyedById = createSelector(
  [getSelectedAssetType],
  (selectedAssetType) => {
    const observationTypes = selectedAssetType?.observations || [];
    const observationTypesKeyedById = new Map();

    observationTypes.forEach((type) => {
      observationTypesKeyedById.set(type.id, type);
    });

    return observationTypesKeyedById as Map<string, ObservationSchema>;
  },
);

export const getSelectedAssetTypeStatuses = createSelector(
  [getSelectedAssetType],
  (selectedAssetType) => {
    const statuses = selectedAssetType?.statuses || [];
    return statuses;
  },
);

export const getColorSchemeForStatus = createSelector(
  [getSelectedAssetType],
  (selectedAssetType): Record<string, string> => {
    const assetStatuses = selectedAssetType?.statuses || [];
    return assetStatuses.reduce((acc, { label, color }) => ({
      ...acc,
      [label]: color || DEFAULT_STATUS_COLOR,
    }), {});
  },
);
