import { MenuProps } from 'antd';
import React from 'react';
import {
  getAssetTypes,
  getAssetsKeyedByTypes,
  getSelectedAssetType,
} from '../../../selectors';
import { DropdownFilter } from '../../atoms';
import { analytics } from '../../../services';
import { getContextStores } from '../../../context';
import { setAssetDetailsAssetId, setTypeFilter } from '../../../actions';

export interface Props {
  size?: 'small' | 'default';
}

const Filters = ({ size = 'default' }: Props) => {
  /* Context */
  const contextStores = getContextStores();
  const { featureState, assetManagerDispatch, layoutDispatch } = contextStores;
  const { assetManagerId } = featureState;

  /* Selectors */
  const assetTypes = getAssetTypes(contextStores);
  const assetsKeyedByTypes = getAssetsKeyedByTypes(contextStores);

  const items = assetTypes.map(({ label_pluralized, id }) => {
    const labelDisplay = `${label_pluralized} (${assetsKeyedByTypes.get(id)?.length || 0})`;
    return {
      label: labelDisplay,
      key: id,
    };
  });

  const onClick: MenuProps['onClick'] = ({ key }) => {
    analytics.trackEvent('Asset Type Dropdown Changed', { asset_type: key });
    setTypeFilter(key, assetManagerId, assetManagerDispatch);
    setAssetDetailsAssetId(null, layoutDispatch);
  };

  // Auto select the first type if nothing is selected
  const currentType = getSelectedAssetType(contextStores);
  const currentTypeLabel = (
    `${currentType?.label_pluralized}`
  );

  return (
    <DropdownFilter
      label={currentTypeLabel}
      menu={{ onClick, items }}
      size={size}
    />
  );
};

export default Filters;
