import { AssetType } from '@ynomia/core/dist/blueprint';
import { StatusChangeAction } from '@ynomia/core/dist/telemetry';
import client from './Client';

export async function fetchAssets(
  tenant: string,
  projectCode: string,
  assetTypes: Array<AssetType>,
  modifiedAfter?: Date | null,
) {
  const assetTypeTypes: string[] = assetTypes?.map((t: AssetType) => t.id) || [];

  const result = await client.server.post(
    `/scratch/telemetry/search/${tenant}/${projectCode}`,
    {
      assetType: assetTypeTypes,
      modifiedAfter: modifiedAfter
        ? new Date(modifiedAfter).toISOString()
        : undefined,
      collections: {
        observations: true,
      },
    },
  );
  return result.data.value;
}

export async function getAssetsHash(
  tenant: string,
  projectCode: string,
  assetTypes: Array<AssetType>,
) {
  const assetTypeTypes: string[] = assetTypes?.map((t: AssetType) => t.id) || [];

  const result = await client.server.post(
    `/scratch/telemetry/search/sha256/${tenant}/${projectCode}`,
    {
      assetType: assetTypeTypes,
      modifiedAfter: undefined, // Always compare against full dataset
      collections: {
        observations: false, // sha256 only cares about asset updated date
      },
    },
  );
  return result.data.value;
}

export async function updateAsset(
  tenant: string,
  projectCode: string,
  assetId: string,
  payload: object,
) {
  await client.server.put(`/scratch/project/${tenant}/${projectCode}/id/${assetId}`, payload);
}

export async function updateAssetStatus(
  tenant: string,
  projectCode: string,
  payload: { [key: string]: any },
) {
  const {
    assetId,
    statusId,
    date,
    isBackdated,
  } = payload;

  const action: StatusChangeAction = {
    action: 'STATUS_CHANGE',
    date: date || new Date(),
    isBackdated,
    tenant: tenant!,
    project: projectCode!,
    assetID: assetId,
    target: statusId,
  };

  await client.server.post('/scratch/telemetry/action', action);
}

export async function createAsset(
  tenant: string,
  projectCode: string,
  payload: object,
) {
  await client.server.post(`/scratch/project/${tenant}/${projectCode}/id`, payload);
}

export async function archiveAsset(
  tenant: string,
  projectCode: string,
  assetId: string,
) {
  await client.server.delete(
    `/scratch/project/${tenant}/${projectCode}/id/${assetId}`,
  );
}
