import { Button, Modal, Tooltip } from 'antd';
import React, { FC, useState } from 'react';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import {
  getScratchProjectCode,
  getSelectedAssetType,
  getSelectedAssetTypeStatuses,
  getTenant,
} from '../../../../../selectors';
import ModalForm from '../../../../atoms/ModalForm';
import { addNewObservation } from '../../../../../actions';
import { analytics } from '../../../../../services';
import { getContextStores } from '../../../../../context';
import { getNextStatusDefault, getProjectTimezone } from '../../../../../utils';
import styles from './styles.module.less';

dayjs.extend(isToday);

interface Props {
  showModal: (open: boolean) => void,
  isModalOpened: boolean,
  disabled: boolean,
  assetId: string,
  tooltip?: string,
}

const AddNewObservation: FC<Props> = ({
  showModal, isModalOpened, assetId, disabled, tooltip,
}) => {
  /* Context  */
  const contextStores = getContextStores();
  const { assetState, assetDispatch } = contextStores;
  const { lastFetchStartTime, assetsKeyedById } = assetState;

  /* Selectors */
  const projectCode = getScratchProjectCode(contextStores);
  const tenant = getTenant(contextStores);
  const statuses = getSelectedAssetTypeStatuses(contextStores);
  const selectedAssetType = getSelectedAssetType(contextStores);
  const statusesFromSelectedType = getSelectedAssetTypeStatuses(contextStores);

  const [submitLoading, setSubmitLoading] = useState(false);
  const projTimezone = getProjectTimezone();

  const currentAsset = assetsKeyedById.get(assetId);

  const onCancel = () => showModal(false);

  const submitForm = async (values: any) => {
    const { time, status } = values;
    let { date } = values;
    analytics.trackEvent(
      'Create Asset Submitted',
      { asset_id: assetId as unknown as string },
    );
    setSubmitLoading(true);

    if (time) {
      date = dayjs(date).hour(time.getHours()).minute(time.getMinutes());
    } else {
      date = dayjs(date).tz(projTimezone);
    }

    const payload = {
      statusId: status,
      date,
      isBackdated: !dayjs(date).tz(projTimezone).isToday(),
      assetId,
    };

    await addNewObservation(
      tenant,
      projectCode,
      [selectedAssetType],
      payload,
      lastFetchStartTime,
      assetDispatch,
    );

    setSubmitLoading(false);
    showModal(false);
  };

  const statusesOptions = statuses.map((status) => {
    const { id, label, order } = status;
    return {
      label,
      value: id,
      statusDefault: status.default,
      order,
    };
  }).sort(
    (a, b) => (a?.order || 0) - (b?.order || 0),
  );

  const defaultValues = {
    status: getNextStatusDefault(
      currentAsset?.observations,
      statusesFromSelectedType,
      statusesOptions,
    )?.value,
    date: dayjs().tz(projTimezone),
  };

  return (
    <>
      <Tooltip title={tooltip || ''}>
        <Button
          type="text"
          style={{ marginTop: -30 }}
          onClick={() => showModal(!isModalOpened)}
          disabled={disabled}
          className="addNewEventHoverOverride"
        >
          <span className={styles.addObservation}>Add New Event</span>
        </Button>
      </Tooltip>
      <Modal
        title="Add New Event"
        className="addNewHistory"
        open={isModalOpened}
        closable={false}
        width={342}
        destroyOnClose
        footer={null}
      >
        <ModalForm
          submitButtonLoading={submitLoading}
          fields={[
            {
              entryComponent: 'picklist',
              id: 'status',
              label: 'Status',
              properties: {
                options: statusesOptions,
              },
              isRequired: true,
              disabled: false,
            },
            {
              disabled: false,
              entryComponent: 'datetime',
              id: 'date',
              isRequired: true,
              label: 'Date',
              properties: {
                restriction: 'past',
                timezone: projTimezone,
                format: 'MMM DD, YYYY',
                allowClear: false,
                split: true,
              },
              show: undefined,
              validationConditions: undefined,
            },
          ]}
          onCancel={onCancel}
          onSubmit={submitForm}
          defaultValues={defaultValues}
        />
      </Modal>
    </>
  );
};

export default AddNewObservation;
