import { UserInfo } from '@ynomia/client';
import { ColumnType } from 'antd/es/table';
import { displayDateInProjectTime, getLayerPathFromLayerID } from '.';
import { AssetUpdateMode } from '../config/constants';
import config from '../config';
import {
  RawCsvAccessRule,
  DataType,
  NewAccessRule,
  VisibilityFilter,
} from '../config/types';
import { AccessRuleColumn } from '../selectors';

const EXCLUDE_LIST = ['fields', 'layerID', 'key'];

export const getFilterCsv = (
  plannedUpdatedAsset,
) => {
  if (!plannedUpdatedAsset) return [];
  const { byTwinID, byLabel } = plannedUpdatedAsset?.updates || {};
  let record: any = {};

  if (byTwinID?.length) { [record] = byTwinID; }
  if (byLabel?.length) { [record] = byLabel; }

  const filtersAssetUpdate = [...new Set([
    ...Object.keys(record),
    ...Object.keys(record?.fields || {}),
    ...['layer'],
  ])];

  return filtersAssetUpdate.filter(key => !EXCLUDE_LIST.includes(key));
};

export const getChanges = (
  currentAsset,
  updatedAsset,
  layersKeyedById,
) => {
  const layerIdCurrentAsset = currentAsset?.destination?.layerID || '';
  const twinIdCurrentAsset = currentAsset?.destination?.twinID?.[0] || '';
  const updatedAssetWithFormattedLayer = updatedAsset;

  if (updatedAsset.layerID) {
    const layerPath = getLayerPathFromLayerID(layersKeyedById, updatedAsset.layerID);
    (updatedAssetWithFormattedLayer as any).layer = `${layerPath} (${updatedAsset.layerID})`;
  }

  const flattenCurrentAsset = {
    ...currentAsset,
    twinID: twinIdCurrentAsset,
    ...currentAsset?.fields,
  };

  if (layerIdCurrentAsset) {
    const layerPath = getLayerPathFromLayerID(layersKeyedById, layerIdCurrentAsset);
    (flattenCurrentAsset as any).layer = `${layerPath} (${layerIdCurrentAsset})`;
  }

  const flattenUpdatedAsset = {
    ...updatedAssetWithFormattedLayer,
    twinID: updatedAssetWithFormattedLayer.twinID,
    ...updatedAssetWithFormattedLayer?.fields,
  };

  return Object.keys(flattenUpdatedAsset)
    .filter(key => !EXCLUDE_LIST.includes(key))
    .map((key) => {
      const currentValue = flattenCurrentAsset[key] || '';
      const newValue = flattenUpdatedAsset[key] || '';

      const getIsValueChanged = (): 'new' | 'updated' | 'none' => {
        if (currentValue !== newValue) {
          return !currentValue ? 'new' : 'updated';
        }
        return 'none';
      };

      return {
        key,
        currentValue,
        isValueChanged: getIsValueChanged(),
        newValue,
      };
    });
};

export const getKeysFromTheFirstItemOfCollection = (
  collection: Map<string, any>,
): Array<string> => {
  const keys = [...collection.keys()];
  const [firstItemsID] = keys;
  return collection.get(firstItemsID)?.map(({ key }) => key) || [];
};

export const getPlannedAssetComparison = (formattedPlannedAsset, asset) => {
  const flattenAsset = { ...asset, ...asset?.fields };
  const formattedPlannedAssetKeys = Object.keys(formattedPlannedAsset);

  // eslint-disable-next-line no-param-reassign
  delete formattedPlannedAsset.fields;
  delete flattenAsset.fields;
  return {
    label: formattedPlannedAsset.label,
    update: formattedPlannedAssetKeys.map((key) => {
      if (key === 'twinID') {
        const twinID = flattenAsset?.destination?.twinID;
        return {
          key,
          currentValue: twinID && twinID[0],
          newValue: formattedPlannedAsset[key],
          isUpdated: twinID !== formattedPlannedAsset[key],
        };
      }
      if (key === 'layerID') {
        const layerID = flattenAsset?.destination?.layerID;
        return {
          key,
          currentValue: layerID,
          newValue: formattedPlannedAsset[key],
          isUpdated: layerID !== formattedPlannedAsset[key],
        };
      }
      return {
        key,
        currentValue: flattenAsset[key],
        newValue: formattedPlannedAsset[key],
        isUpdated: flattenAsset[key] !== formattedPlannedAsset[key],
      };
    }),
  };
};

export const exportToSpreadsheet = async (data, fileName) => {
  /**
   * Only load in heavy libraries on demand
   * https://rollupjs.org/tutorial/#code-splitting
   */

  const { utils, writeFile } = await import('xlsx');
  const wb = utils.book_new();

  data.forEach(({ sheet, content }) => {
    let ws;

    if (Array.isArray(content[0])) {
      const json = content.map((row) => {
        const jsonToSheet = utils.json_to_sheet(row || []);
        return utils.sheet_to_json(jsonToSheet, { header: 1 });
      });

      ws = utils.json_to_sheet(
        json.reduce((arr, elem) => arr.concat([...elem, '']), []),
        { skipHeader: true },
      );
    } else {
      ws = utils.json_to_sheet(content);
    }

    utils.book_append_sheet(wb, ws, sheet);
  });

  writeFile(wb, `${fileName}.xlsx`);
};

export const getMetadata = (metadata: {
  tenant: string | undefined,
  projectName: string,
  userInfo: UserInfo,
  timezone: string | undefined,
  assetUpdateMode?: AssetUpdateMode | null,
  fileName: string | null,
  unchanged?: number,
  importTime: Date | null,
  changed?: number,
  totalCount?: number,
  totalBefore?: number,
}) => {
  const metadataKeys = Object.keys(metadata);

  const content: {
    [key: string]: string | number | undefined | null;
  } = {};

  const { timezone } = metadata;

  metadataKeys.forEach((key) => {
    if (key === 'userInfo') {
      const userInfo = metadata[key];
      const { email, name } = userInfo;
      content.name = name;
      content.email = email;
      return;
    }
    if (key === 'importTime') {
      const importTime = metadata[key];
      content.time = importTime
        ? displayDateInProjectTime(importTime?.toString(), 'DD/MM/YYYY hh:mm') : '';
      content.timeUTC = importTime ? importTime.toISOString() : '';
      return;
    }
    if (key === 'assetUpdateMode') {
      content.mode = metadata[key] === 'upsert'
        ? 'Asset List to Update and Add (No Delete)' : 'Asset List to Update, Add and Delete';
    }
    content[key] = metadata[key];
  });
  content.environment = config.environment;

  const contentKeys = Object.keys(content);

  const columns = [
    {
      label: 'Environment',
      value: 'environment',
    },
    {
      label: 'Tenant',
      value: 'tenant',
    },
    {
      label: 'Project',
      value: 'projectName',
    },
    {
      label: 'User',
      value: 'name',
    },
    {
      label: 'Email',
      value: 'email',
    },
    {
      label: 'File',
      value: 'fileName',
    },
    {
      label: 'Mode',
      value: 'mode',
    },
    {
      label: 'Total Before',
      value: 'totalBefore',
    },
    {
      label: 'Total After',
      value: 'totalCount',
    },
    {
      label: 'Changed',
      value: 'changed',
    },
    {
      label: 'Unchanged',
      value: 'unchanged',
    },
    {
      label: `Timezone (${timezone})`,
      value: 'time',
    },
    {
      label: 'ISO 8901',
      value: 'timeUTC',
    },
  ];

  return {
    sheet: 'Metadata',
    columns: columns.filter(column => contentKeys.includes(column.value)),
    content: [content],
  };
};

export const convertRawCsvToNewAccessRule = (
  accessRulesColumns: AccessRuleColumn[],
  visibilityFilters: VisibilityFilter[],
  accessRule: RawCsvAccessRule,
): NewAccessRule => {
  const accessRulesColumnsKeyedByTitle = new Map(
    accessRulesColumns?.map(column => [column.title, column]),
  ) as Map<string, ColumnType<DataType>>;
  const accessRuleConvertedKey = Object.fromEntries(
    Object.entries(accessRule).map(
      ([k, v]) => [accessRulesColumnsKeyedByTitle.get(k)?.key, v],
    ),
  );

  const {
    name, email, roles, enabled, mfa, notes, filters,
  } = accessRuleConvertedKey;

  const visibilityFilterIds: string[] | undefined = filters?.split(',').map(
    filterName => visibilityFilters.find(filter => filter.label === filterName)?.id,
  );

  return {
    email: email.toLowerCase(),
    name,
    notes: notes || undefined,
    roles: roles?.split(',') || [],
    enabled,
    filter_ids: visibilityFilterIds,
    mfa,
  };
};
