import { createSelector } from 'reselect';
import omit from 'lodash/omit';
import {
  getAssetManagerFilters,
  getAssetManagerTwinPlanId,
  getAssetsArray,
  getAssetStatusesKeyedById,
  getAssetTypes,
  getCurrentPlanBandsForCharts,
  getPlansKeyedByTwinId,
  getSelectedAssetType,
} from '..';
import { getFilteredAssets, getFilteredAssetsContext, keyAssetsByBandId } from '.';
import { keyAssetsByStatus } from '../../utils';
import map from '../map';
import { FieldSummariesConfig } from '../../config/types';

export const getFieldSummariesConfigByAssetManagerId = createSelector(
  [map.features],
  (features): Map<string, { [key: string]: FieldSummariesConfig }> => {
    if (!features) return new Map();
    return new Map(Object.keys(features).map(key => [key, features[key]?.field_summaries]));
  },
);

export const getFieldSummariesConfig = createSelector(
  [
    map.assetManagerId,
    getFieldSummariesConfigByAssetManagerId,
    getSelectedAssetType,
  ],
  (
    assetManagerId,
    summaryByFeature,
    selectedAssetType,
  ) => {
    const config = summaryByFeature.get(assetManagerId);
    if (!config) return [];
    return config[selectedAssetType.id];
  },
);

const getFilteredAssetsForFieldSummaryKeyedByField = createSelector(
  [
    getAssetManagerFilters,
    getFilteredAssetsContext,
    getFieldSummariesConfig,
  ],
  (
    assetManagerFilters,
    filteredAssetsContext,
    fieldSummariesConfig,
  ) => {
    const {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      tableFilter,
      openedLayerId,
      selectedBandIds,
      multiSearchModeFilter,
      selectedLayerIds,
      fieldFilters,
    } = assetManagerFilters;
    return new Map(fieldSummariesConfig?.map((({ key }) => {
      const customFieldFilters = omit(fieldFilters, key);

      const filteredAssets = getFilteredAssets(filteredAssetsContext, {
        searchTags,
        searchText,
        dateFilter,
        typeFilter,
        selectedLayerIds,
        assetStatusFilter,
        selectedBandIds,
        observationTypeFilter,
        tableFilter,
        openedLayerId,
        multiSearchModeFilter,
        fieldFilters: customFieldFilters,
      });
      return [key, filteredAssets];
    })));
  },
);

export const getAssetsKeyedByStatusForFieldSummaries = createSelector(
  [getAssetTypes, getFilteredAssetsForFieldSummaryKeyedByField],
  (assetTypes, filteredAssetsForFieldSummaryKeyedByField) => new Map(
    [...filteredAssetsForFieldSummaryKeyedByField].map(([key, value]) => [
      key,
      keyAssetsByStatus(assetTypes, value),
    ]),
  ),
);

export const getAssetsKeyedByBandIdForFieldSummaries = createSelector(
  [getPlansKeyedByTwinId, getFilteredAssetsForFieldSummaryKeyedByField],
  (plansKeyedByTwinId, filteredAssetsForFieldSummaryKeyedByField) => new Map(
    [...filteredAssetsForFieldSummaryKeyedByField].map(([key, value]) => [
      key,
      keyAssetsByBandId(plansKeyedByTwinId, value),
    ]),
  ),
);

export const getFieldSummaryOptionsKeyedByField = createSelector(
  [
    map.presenterMode,
    getAssetManagerFilters,
    getAssetsArray,
    getSelectedAssetType,
    getFieldSummariesConfig,
  ],
  (
    presenterMode,
    assetManagerFilters,
    assetsArray,
    selectedAssetType,
    fieldSummariesConfig,
  ): Map<string, Array<{ label: string, value: string }>> => {
    if (!fieldSummariesConfig) return new Map();
    return new Map(fieldSummariesConfig?.map(({ key, generateOptionsFromAssetList }) => {
      const fields = selectedAssetType?.fields || [];
      const { fieldFilters } = assetManagerFilters;
      const fieldFilter = fieldFilters[key];
      let fieldOptions: Array<{
        label: string,
        value: string,
      }> = [];
      const fieldListFromAsset = new Map(
        assetsArray?.map(asset => [`${asset.fields?.[key] || ''}`, asset]),
      );

      const fieldConfig = fields?.find(field => field.id === key);
      if (generateOptionsFromAssetList) {
        fieldOptions = [...fieldListFromAsset.keys()]
          .filter(value => !!value)
          .sort()
          .map(value => ({ label: value, value }));
      } else if (fieldConfig) {
        fieldOptions = fieldConfig?.properties?.options;
      }

      const hasAssetWithoutField = fieldListFromAsset.get('');
      if (hasAssetWithoutField) {
        const blankOption = {
          label: !fieldOptions.length && selectedAssetType
            ? selectedAssetType.name : '(blank)',
          value: '',
        };
        fieldOptions = [...fieldOptions, blankOption];
      }

      if (presenterMode && fieldFilter?.length) {
        fieldOptions = fieldOptions.filter(
          ({ label }) => fieldFilter.includes(label),
        );
      }

      return [key, fieldOptions];
    }));
  },
);

export const getChartistDataKeyedByFieldSummary = createSelector(
  [
    getAssetManagerTwinPlanId,
    getAssetStatusesKeyedById,
    getAssetsKeyedByStatusForFieldSummaries,
    getSelectedAssetType,
    getFieldSummaryOptionsKeyedByField,
    getCurrentPlanBandsForCharts,
    getAssetsKeyedByBandIdForFieldSummaries,
    getFieldSummariesConfig,
  ],
  (
    twinPlanId,
    assetStatusesKeyedById,
    assetsKeyedByStatusForFieldSummaries,
    selectedAssetType,
    fieldSummaryOptionsKeyedByField,
    currentPlanBands,
    assetsKeyedByBandIdForFieldSummaries,
    fieldSummariesConfig,
  ) => {
    const { statuses } = selectedAssetType;
    if (!fieldSummariesConfig) return new Map();
    return new Map(fieldSummariesConfig?.map(({ key }) => {
      const options = fieldSummaryOptionsKeyedByField.get(key)!;
      const getStatus = () => {
        const series = statuses?.map((status) => {
          const statusId = status.id;
          const assets = assetsKeyedByStatusForFieldSummaries.get(key)?.get(statusId)!;
          const statusLabel = assetStatusesKeyedById.get(statusId)?.label;
          let newSubSeries: Array<{ meta: string, value: number }> = [];

          newSubSeries = options.map(({ value }) => {
            const count = assets?.filter(({ fields }) => {
              const assetVal = fields?.[key];
              if (assetVal === '' || assetVal === undefined) {
                return value === '';
              }
              return value === `${assetVal}`;
            })?.length || 0;
            return {
              meta: statusLabel!,
              value: count,
            };
          });
          return newSubSeries;
        });
        return series?.reverse() || [];
      };

      const getPlan = () => currentPlanBands.map((band) => {
        const assets = assetsKeyedByBandIdForFieldSummaries.get(key)?.get(band.id) || [];
        const newSubSeries = options?.map(({ value }) => {
          const count = assets.filter(asset => asset?.fields?.[key] === value).length;
          return {
            meta: band.label,
            value: count,
          };
        });

        return newSubSeries;
      }).reverse();
      const labels = options.map(({ label }) => label) as Array<string>;
      const data = {
        labels,
        series: twinPlanId ? getPlan() : getStatus(),
      };

      return [key, data];
    }));
  },
);
