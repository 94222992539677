import React, {
  createContext, FC, useContext, useReducer,
} from 'react';
import { PlannedTwinID } from '@ynomia/core/dist/blueprint';
import { ContextDispatch } from '../config/types';
import PlanReducer from '../reducers/plans';

export type PlanStateType = {
  plansKeyedById: Map<string, PlannedTwinID>
  isFetchingPlans: boolean
};

const initialState: PlanStateType = {
  plansKeyedById: new Map(),
  isFetchingPlans: false,
};

export const PlanContext = createContext({
  state: initialState,
  dispatch: (() => { }) as ContextDispatch,
});

export const usePlan = (): [PlanStateType, ContextDispatch] => {
  const { state, dispatch } = useContext(PlanContext);
  return [state, dispatch];
};

export const PlanProvider:FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [state, dispatch] = useReducer(PlanReducer, initialState);

  return (
    <PlanContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </PlanContext.Provider>
  );
};
