import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import type { Dayjs } from 'dayjs';
import {
  ContextDispatch,
  DataType,
  ToggleAssetStatus,
  ToggleObservation,
} from '../config/types';
import {
  REMOVE_ALL_ASSET_STATUS_FILTER,
  REMOVE_ALL_FILTERS,
  REMOVE_ALL_OBSERVATION_TYPE_FILTER,
  REMOVE_ASSET_STATUS_FILTER,
  REMOVE_OPENED_LAYER_ID_FILTER,
  REMOVE_SELECTED_LAYER_IDS_FILTER,
  REMOVE_SELECTED_PLAN_BAND_ID,
  RESET_SELECTED_ASSETS,
  SELECT_ASSET_STATUS_FILTER,
  SELECT_OBSERVATION_TYPE_FILTER,
  SET_ASSET_LAYER_FILTER,
  SET_COLLAPSABLE,
  SET_DATE_FILTER,
  SET_FIELD_FILTERS,
  SET_MULTI_SEARCH_MODE_FILTER,
  SET_OPENED_LAYER_ID_FILTER,
  SET_SEARCH_TAGS,
  SET_SEARCH_TEXT,
  SET_SELECTED_ASSETS,
  SET_SELECTED_PLAN_BAND_IDS,
  SET_SHOW_SELECTED_ONLY,
  SET_TABLE_FILTER,
  SET_TABLE_PAGINATION,
  SET_TABLE_SORTER,
  SET_TIME_TRAVEL_DATE,
  SET_TWIN_PLAN_ID,
  SET_TYPE_FILTER,
} from './actionTypes';

export const setSearchTags = (
  searchTags: Array<string>,
  assetManagerId: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_SEARCH_TAGS,
    payload: {
      assetManagerId,
      searchTags,
    },
  });
};

export const setSearchText = (
  searchText: string,
  assetManagerId: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_SEARCH_TEXT,
    payload: {
      searchText,
      assetManagerId,
    },
  });
};

export const setDateFilter = (
  dateFilter: [Dayjs, Dayjs] | [],
  assetManagerId: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_DATE_FILTER,
    payload: {
      dateFilter,
      assetManagerId,
    },
  });
};

export const setSelectedBandIds = (
  selectedBandIds: Array<string>,
  assetManagerId: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_SELECTED_PLAN_BAND_IDS,
    payload: {
      selectedBandIds,
      assetManagerId,
    },
  });
};

export const setTypeFilter = (
  id: string,
  assetManagerId: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_TYPE_FILTER,
    payload: {
      typeFilter: id,
      assetManagerId,
    },
  });
};

export const setTableFilter = (
  tableFilter: Record<string, FilterValue | null>,
  assetManagerId: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_TABLE_FILTER,
    payload: {
      tableFilter,
      assetManagerId,
    },
  });
};

export const setTableSorter = (
  tableSorter: SorterResult<DataType>,
  assetManagerId: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_TABLE_SORTER,
    payload: {
      tableSorter,
      assetManagerId,
    },
  });
};

export const setShowSelectedOnly = (
  showSelectedOnly: boolean,
  assetManagerId: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_SHOW_SELECTED_ONLY,
    payload: {
      showSelectedOnly,
      assetManagerId,
    },
  });
};

export const setSelectedAssets = (
  selectedAssets: Array<string>,
  assetManagerId: string,
  manual: Boolean,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_SELECTED_ASSETS,
    payload: {
      selectedAssets,
      assetManagerId,
      manual,
    },
  });
};

export const resetSelectedAssets = (
  assetManagerId: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: RESET_SELECTED_ASSETS,
    payload: {
      assetManagerId,
    },
  });
};

export const setTablePagination = (
  pagination: TablePaginationConfig,
  assetManagerId: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_TABLE_PAGINATION,
    payload: {
      pagination,
      assetManagerId,
    },
  });
};

export const selectAssetStatusFilter = (
  toggledValue: ToggleAssetStatus,
  id: string,
  assetManagerId: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SELECT_ASSET_STATUS_FILTER,
    payload: {
      toggledValue,
      id,
      assetManagerId,
    },
  });
};

export const selectObservationTypeFilter = (
  toggledValue: ToggleObservation,
  id: string,
  assetManagerId: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SELECT_OBSERVATION_TYPE_FILTER,
    payload: {
      id,
      toggledValue,
      assetManagerId,
    },
  });
};

export const setAssetLayerFilter = (
  selectedLayerIds: Array<string>,
  assetManagerId: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_ASSET_LAYER_FILTER,
    payload: {
      selectedLayerIds,
      assetManagerId,
    },
  });
};

export const removeAllFilters = (assetManagerId: string, dispatch: ContextDispatch) => {
  dispatch({
    type: REMOVE_ALL_FILTERS,
    payload: {
      assetManagerId,
    },
  });
};

export const removeAssetStatusFilter = (
  id: string,
  assetManagerId: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: REMOVE_ASSET_STATUS_FILTER,
    payload: {
      id,
      assetManagerId,
    },
  });
};

export const removeSelectedBandId = (
  id: string,
  assetManagerId: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: REMOVE_SELECTED_PLAN_BAND_ID,
    payload: {
      id,
      assetManagerId,
    },
  });
};

export const removeAllAssetStatusFilter = (assetManagerId: string, dispatch: ContextDispatch) => {
  dispatch({
    type: REMOVE_ALL_ASSET_STATUS_FILTER,
    payload: {
      assetManagerId,
    },
  });
};

export const removeAllObservationTypeFilter = (
  assetManagerId: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: REMOVE_ALL_OBSERVATION_TYPE_FILTER,
    payload: {
      assetManagerId,
    },
  });
};

export const removeSelectedLayerIds = (assetManagerId: string, dispatch: ContextDispatch) => {
  dispatch({
    type: REMOVE_SELECTED_LAYER_IDS_FILTER,
    payload: {
      assetManagerId,
    },
  });
};

export const setFieldFilter = (
  key: string,
  value: Array<string>,
  assetManagerId: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_FIELD_FILTERS,
    payload: {
      key,
      value,
      assetManagerId,
    },
  });
};

export const setOpenedLayerFilter = (
  openedLayerId: string | undefined,
  assetManagerId: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_OPENED_LAYER_ID_FILTER,
    payload: {
      assetManagerId,
      openedLayerId,
    },
  });
};

export const setMultiSearchMode = (
  multiSearchModeFilter: 'or' | 'and',
  assetManagerId: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_MULTI_SEARCH_MODE_FILTER,
    payload: {
      multiSearchModeFilter,
      assetManagerId,
    },
  });
};

export const setTimeTravelDate = async (
  timeTravelDate: Dayjs | null,
  assetManagerId: string,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_TIME_TRAVEL_DATE,
    payload: {
      timeTravelDate,
      assetManagerId,
    },
  });
  setDateFilter([], assetManagerId, dispatch);
};

export const removeOpenedLayerId = (assetManagerId: string, dispatch: ContextDispatch) => {
  dispatch({
    type: REMOVE_OPENED_LAYER_ID_FILTER,
    payload: {
      assetManagerId,
    },
  });
};

export const setCollapsable = (
  assetManagerId: string,
  id: string,
  state: { isCollapsed?: boolean, showAll?: boolean },
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_COLLAPSABLE,
    payload: {
      assetManagerId,
      id,
      state,
    },
  });
};

export const setTwinPlanId = (
  assetManagerId: string,
  twinPlanId: string | null,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_TWIN_PLAN_ID,
    payload: {
      assetManagerId,
      twinPlanId,
    },
  });
};
