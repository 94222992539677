import {
  Alert, Button, Card, Checkbox, Collapse, Progress, Typography,
} from 'antd';
import {
  CheckCircleOutlined, EyeOutlined, WarningOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { FormValues } from '@ynomia/dynamic-form';
import TextArea from 'antd/es/input/TextArea';
import { isEmpty } from 'lodash';
import { models } from '@ynomia/core';
import { JsonEditor, ModelViewer } from '../../atoms';
import {
  getAssetTypes,
  getColorSchemeForStatus,
  getFilteredTwinData,
  getScratchProjectCode,
  getSelectedAssetType,
  getTenant,
} from '../../../selectors';
import {
  setIsLoadingPercentage,
  setIsTwinLoading,
  setIsTwinReady,
} from '../../../actions';
import AssetTypeDropdown from '../AssetTypeDropdown';
import ModalForm from '../../atoms/ModalForm';
import { PRESET_TWIN_ID_NAME } from '../../../config/constants';
import config from '../../../config';
import { getContextStores } from '../../../context';
import styles from './styles.module.less';

export type ApsModel = {
  bucketKey: string,
  location: string,
  objectId: string,
  modelId: string,
  sha1: string,
  size: number
  manifest: {
    type: 'manifest',
    hasThumbnail: string,
    status: string,
    progress: string,
    region: string,
    urn: string,
    version: string,
    derivatives: Array<any>
  }
};

const DEFAULT_MAPPING_FILTER = JSON.stringify([{
  key: "ie. 'Type Name'",
  value: "ie. 'B00' or regex 'B{1}\\d{2}'",
}], null, 2);

export interface Props {
  modelId: string,
  modelsKeyedById: Record<string, models.ModelResponse>,
  apsModel: ApsModel,
  disableUpdate: boolean,
  updateModel: (id: string, model: Partial<models.Model>) => Promise<void>
}

const ModelEditor = ({
  modelId,
  modelsKeyedById,
  apsModel,
  disableUpdate,
  updateModel,
}: Props) => {
  /* Context */
  const contextStores = getContextStores();

  /* Selectors */
  const twinData = getFilteredTwinData(contextStores);
  const colorScheme = getColorSchemeForStatus(contextStores);
  const project = getScratchProjectCode(contextStores);
  const tenant = getTenant(contextStores);

  const assetTypes = getAssetTypes(contextStores);
  const { layoutState, digitalTwinDispatch, digitalTwinState } = contextStores;
  const currentType = getSelectedAssetType(contextStores);
  const { isTwinLoading } = digitalTwinState;
  const { assetDetailsAssetId } = layoutState;
  const { twin } = currentType;
  const forgeConfig = twin?.forge;

  const [savingProperties, setSavingProperties] = useState<boolean>(false);
  const [requestMapping, setRequestMapping] = useState<string>();
  const [savingMap, setSavingMapping] = useState<boolean>(false);
  const [twinIDProgress, setTwinIdProgress] = useState<number>();
  const [
    tempTwinIdMapping,
    setTempTwinIdMapping,
  ] = useState<{ [twinID: string]: Array<number> } | null>(null);
  const [
    modelMappingOverride,
    setModelMappingOverride,
  ] = useState<{ [twinID: string]: Array<number> } | null>(null);
  const [mappingFilters, setMappingFilters] = useState<string>(DEFAULT_MAPPING_FILTER);
  const [mapLargestElementOnly, setMapLargestElementOnly] = useState<boolean>(false);

  const mappingFiltersEdited = mappingFilters !== DEFAULT_MAPPING_FILTER;
  const validMappingFilters = () => {
    if (!mappingFiltersEdited) return true;
    try {
      JSON.stringify(JSON.parse(mappingFilters), null, 2);
      return true;
    } catch (e) {
      return false;
    }
  };
  const getFormattedMappingFilters = () => {
    try {
      return JSON.stringify(JSON.parse(mappingFilters), null, 2);
    } catch (e) {
      return mappingFilters;
    }
  };
  const isMappingFiltersValid = () => {
    try {
      JSON.parse(mappingFilters);
      return true;
    } catch (e) {
      return false;
    }
  };
  const formattedMappingFilters = getFormattedMappingFilters();

  const { host } = config;
  const model = modelsKeyedById[modelId];
  const { twinIdField, isMapped } = model;

  useEffect(() => {
    setTempTwinIdMapping(null);
  }, [twinIdField]);

  useEffect(() => {
    if (tempTwinIdMapping) {
      setModelMappingOverride(tempTwinIdMapping);
    } else {
      setModelMappingOverride(null);
    }
    setTwinIdProgress(0);
  }, [tempTwinIdMapping]);

  const onTwinLoading = (percentage) => {
    /**
     * Percentage goes backwards sometimes (ie. from 100% -> 95%)
     * This check prevents the loading bar from showing again.
     * */
    if (percentage === 0) {
      setIsTwinLoading(true, digitalTwinDispatch);
    }

    setIsLoadingPercentage(percentage, digitalTwinDispatch);

    if (percentage === 100) {
      setIsTwinLoading(false, digitalTwinDispatch);
    }
  };

  const onPropertiesSave = async (submission: FormValues) => {
    setSavingProperties(true);
    const update = { ...model, ...submission };
    if (submission.twinIdField !== twinIdField) update.mapping = {};
    await updateModel(modelId, update);
    setSavingProperties(false);
  };

  const generateTwinIdMappingStatus = () => {
    if (requestMapping) return (null);
    if (tempTwinIdMapping || savingMap) {
      return (
        <>
          <EyeOutlined />
          Model Preview
        </>
      );
    }
    if (isMapped) {
      return (
        <>
          <CheckCircleOutlined style={{ color: 'green' }} />
          Mapped!
        </>
      );
    }
    return (
      <>
        <WarningOutlined style={{ color: 'orange' }} />
        Mapping Required
      </>
    );
  };

  const displayMappingStatus = () => {
    const showModelMappedAlert = isMapped && !requestMapping && !savingMap && !tempTwinIdMapping;
    const translationProgress = apsModel?.manifest?.progress;
    const apsModelTranslating = apsModel && translationProgress !== 'complete';
    return (
      <>
        {showModelMappedAlert && (
        <Alert
          message={`The model map was saved successfully.
        If the preview looks correct, no further action is required.`}
          type="success"
          showIcon
        />
        )}
        {apsModelTranslating && (
        <Alert
          message={`The model is still translating (${translationProgress}).
        Please come back to perform TwinId mapping when this is complete.`}
          type="warning"
          showIcon
        />
        )}
        {(isTwinLoading && !apsModelTranslating) && (
        <Alert
          message="Waiting for the model to load..."
          type="info"
          showIcon
        />
        )}
      </>
    );
  };

  const displayMappingResult = () => {
    const validTwinIdMapping = tempTwinIdMapping && !isEmpty(tempTwinIdMapping);
    return (
      <>
        {(tempTwinIdMapping && !isEmpty(tempTwinIdMapping)) && (
        <Alert
          message={`Please check if the model is mapped correctly.
        The preview shows what the model looks like with live assets.
        If no assets are present in the system, the model will not display correctly.`}
          type="info"
          showIcon
        />
        )}
        {(tempTwinIdMapping && isEmpty(tempTwinIdMapping)) && (
        <Alert
          message={`We've analyzed the model 
      and found nothing under the property "${twinIdField}".
      Please double check that this field is correct.`}
          type="info"
          showIcon
        />
        )}
        {
        tempTwinIdMapping
        && (
        <>
          {`Mapped ${Object.keys(tempTwinIdMapping).length} elements`}
          {validTwinIdMapping
            && <TextArea readOnly value={JSON.stringify(tempTwinIdMapping, null, 2)} />}
        </>
        )
      }
      </>
    );
  };

  const onExtractMapping = () => {
    setTwinIdProgress(0);
    setRequestMapping(twinIdField);
    setTempTwinIdMapping(null);
  };

  const onMappingConfirmation = async () => {
    setSavingMapping(true);
    await updateModel(
      modelId,
      {
        mapping: tempTwinIdMapping!,
        twinIdField,
      },
    );
    setSavingMapping(false);
    setTempTwinIdMapping(null);
  };

  const renderAdvancedFilters = (
    <>
      For Advanced Filters to work,
      make sure &#34;Hide non-mapped elements&#34; is applied under properties.
      <br />
      <br />
      <b>Check this to ignore small details (ie. M&E or rebar inside a precast element)</b>
      <Checkbox
        checked={mapLargestElementOnly}
        onChange={e => setMapLargestElementOnly(e.target.checked)}
      >
        Map Largest Element Only
      </Checkbox>
      <br />
      <br />
      <b>OR for finer controls based on the element properties</b>
      <JsonEditor
        content={{ text: formattedMappingFilters }}
        onChange={(content) => {
          const { text } = content;
          setMappingFilters(text);
        }}
        onReset={() => setMappingFilters(DEFAULT_MAPPING_FILTER)}
        height={300}
      />
    </>
  );

  return (
    <div className={styles.container}>
      <div className={styles.modelContainer}>
        <ModelViewer
          ref={React.createRef()}
          source={host.twin}
          modelCode="settings"
          mappingModelId={modelId}
          twinData={twinData}
          config={JSON.stringify(colorScheme)}
          loadModels={[modelId]}
          project={project}
          tenant={tenant}
          isTwinLoading={false}
          onTwinLoading={onTwinLoading}
          onTwinReady={(onTwinReady) => {
            setIsTwinReady(onTwinReady, digitalTwinDispatch);
          }}
          assetDetailsAssetId={assetDetailsAssetId}
          requestModelMapping={requestMapping}
          mappingIgnoreArray={
              mappingFiltersEdited && isMappingFiltersValid()
                ? JSON.parse(mappingFilters)
                : undefined
            }
          onMapProgress={p => setTwinIdProgress(p)}
          onMapComplete={(m) => {
            setRequestMapping(undefined);
            setTempTwinIdMapping(m);
          }}
          mappingOverride={modelMappingOverride || undefined}
          forgeConfig={forgeConfig!}
          mapLargestElementOnly={mapLargestElementOnly}
        />

        <div className={styles.assetTypeDropdownContainer}>
          <Card title="Preview Asset Type" size="small">
            <AssetTypeDropdown size="small" />
          </Card>
        </div>
      </div>
      <div className={styles.settingsContainer}>
        <div className={styles.propertiesContainer}>
          <Typography.Title level={5}>
            Properties
          </Typography.Title>
          <ModalForm
            fields={[
              {
                entryComponent: 'picklist',
                id: 'assetTypes',
                label: 'Enabled Asset Type(s)',
                properties: {
                  options: assetTypes.map(assetType => ({
                    label: assetType.label,
                    value: assetType.id,
                  })),
                  multi: true,
                },
                isRequired: true,
              },
              {
                entryComponent: 'picklist',
                id: 'twinIdField',
                label: 'Twin ID Property Key',
                properties: {
                  options: PRESET_TWIN_ID_NAME.map(label => ({
                    label,
                    value: label,
                  })),
                  tags: true,
                  maxCount: 1,
                },
                isRequired: true,
              },
              {
                entryComponent: 'checkbox',
                id: 'loadMappedIdsOnly',
                label: 'Hide non-mapped elements for users to better performance'
                  + ' (Must be enabled for advanced filters to work)',
              },
            ]}
            submitButtonText="Save"
            onSubmit={onPropertiesSave}
            hideCancelButton
            defaultValues={model}
            submitButtonLoading={savingProperties}
            isDisabled={disableUpdate}
          />
        </div>
        <div className={styles.mappingContainer}>
          <div className={styles.mappingHeader}>
            <Typography.Title level={5}>
              TwinId Mapping
            </Typography.Title>
            <div className={styles.mappingSubHeader}>
              {generateTwinIdMappingStatus()}
            </div>
          </div>
          {displayMappingStatus()}
          <Collapse
            size="small"
            items={[{
              key: '1',
              label: `Advanced Filters${mappingFiltersEdited ? '*' : ''}`,
              children: renderAdvancedFilters,
            }]}
          />
          <Button
            onClick={onExtractMapping}
            disabled={
              isTwinLoading
              || savingMap
              || !!tempTwinIdMapping
              || !validMappingFilters()
            }
            loading={!!requestMapping}
          >
            Extract &#34;
            {twinIdField}
            &#34; Mapping
          </Button>
          {(!tempTwinIdMapping) && <Progress percent={twinIDProgress} />}
          {displayMappingResult()}
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 10,
            justifyContent: 'right',
          }}
          >
            <Button
              disabled={!tempTwinIdMapping || disableUpdate}
              onClick={() => setTempTwinIdMapping(null)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={!tempTwinIdMapping || disableUpdate}
              loading={savingMap}
              onClick={onMappingConfirmation}
            >
              Save Mapping
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModelEditor;
