import { PlannedTwinID } from '@ynomia/core/dist/blueprint';
import { SET_PLANS, SET_FETCHING_PLANS } from '../actions/actionTypes';
import { ContextDispatchParam } from '../config/types';
import { PlanStateType } from '../context';

export default (
  state: PlanStateType,
  action: ContextDispatchParam,
): PlanStateType => {
  switch (action.type) {
    case SET_PLANS: {
      const plansKeyedById = new Map<string, PlannedTwinID>([
        ...state.plansKeyedById,
        ...action.payload,
      ]);

      return {
        ...state,
        plansKeyedById,
      };
    }
    case SET_FETCHING_PLANS:
      return {
        ...state,
        isFetchingPlans: action.payload,
      };
    default:
      return state;
  }
};
