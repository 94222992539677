import React, { FC, useEffect } from 'react';
import { Button } from 'antd';
import {
  getAnnouncement, getScratchProjectCode, getTenant, getUserInfo,
} from '../../../selectors';
import { dismissAnnouncement } from '../../../services';
import { getContextStores } from '../../../context';
import { getUser } from '../../../actions';
import styles from './styles.module.less';
import { Icon } from '../../atoms';

const Announcement: FC = () => {
  /* Context  */
  const contextStores = getContextStores();
  const {
    userDispatch,
    assetState,
    userState,
  } = contextStores;

  /* Context  */
  const { layoutState } = contextStores;
  const { presenterMode } = layoutState;

  const { initialAssetFetchComplete } = assetState;

  /* Selectors  */
  const announcement = getAnnouncement(contextStores);
  const projectCode = getScratchProjectCode(contextStores);

  const userInfo = getUserInfo(contextStores);
  const tenant = getTenant(contextStores);

  const projectMeta = userState.user.project_meta || [];

  const [currentProjectMeta] = projectMeta?.filter(({ project }) => projectCode === project) || {};
  const { text, id } = announcement?.message || {};

  const onClose = async () => {
    if (id) {
      await dismissAnnouncement(tenant, projectCode, id, userInfo.id);
      await getUser(userInfo.id, userDispatch);
    }
  };

  const showAnnouncement = (
    initialAssetFetchComplete
    && announcement?.enabled
    && !currentProjectMeta?.dismissed_announcement_ids.includes(id)
  );

  useEffect(() => {
    getUser(userInfo.id, userDispatch);
  }, []);

  return showAnnouncement && !presenterMode ? (
    <div className={styles.container}>
      <span className={styles.text}>
        {text}
      </span>
      <Button type="text" onClick={() => onClose()} icon={<Icon name="close" />} />
    </div>
  ) : null;
};
export default Announcement;
