import { createSelector } from 'reselect';
import { PlannedTwinID, PlanResultBand } from '@ynomia/core/dist/blueprint';
import map from './map';
import {
  getAssetManagerTwinPlanId,
  getSelectedAssetType,
} from '.';
import { UNKNOWN_BAND } from '../config/constants';

export const getPlansArray = createSelector(
  [map.plansKeyedById],
  (plansKeyedById) => {
    const keys = [...plansKeyedById.keys()];
    return keys.map(id => plansKeyedById.get(id)) as Array<PlannedTwinID>;
  },
);

export const getPlansKeyedByTwinId = createSelector(
  [getPlansArray],
  plans => new Map(plans.map(plan => [plan.twinID, plan])) as Map<string, PlannedTwinID>,
);

export const getPlanConfigurationsKeyedById = createSelector(
  [getSelectedAssetType],
  (selectedAssetType) => {
    const plans = selectedAssetType?.plans || [];
    return new Map(plans.map(plan => [plan.id, plan]));
  },
);

export const getCurrentPlanConfiguration = createSelector(
  [getPlanConfigurationsKeyedById, getAssetManagerTwinPlanId],
  (planConfigurationsKeyedById, twinPlanId) => planConfigurationsKeyedById.get(twinPlanId!),
);

export const getCurrentPlanBandsForCharts = createSelector(
  [getCurrentPlanConfiguration],
  currentPlanConfiguration => [
    ...currentPlanConfiguration?.bands || [],
    UNKNOWN_BAND,
  ],
);

export const getColorSchemeForBand = createSelector(
  [getCurrentPlanBandsForCharts],
  bands => bands.reduce((
    prev,
    band,
  ) => ({ ...prev, [band.label]: band.color }), {}),
);

export const getBandsKeyedById = createSelector(
  [getPlanConfigurationsKeyedById],
  (planConfigurationsKeyedById) => {
    const bandsKeyedById = new Map();

    planConfigurationsKeyedById.forEach((value) => {
      const { bands } = value;
      bands.forEach((band) => {
        bandsKeyedById.set(band.id, band);
      });
    });

    bandsKeyedById.set('unknown', {
      id: 'unknown',
      label: 'Unknown',
      color: '#e1e1e1',
      tolerance: 0,
    });

    return bandsKeyedById as Map<string, PlanResultBand>;
  },
);
