import React, {
  createContext, FC, useContext, useReducer,
} from 'react';
import { models } from '@ynomia/core';
import { ContextDispatch } from '../config/types';
import DigitalTwinReducer from '../reducers/digitalTwin';

export type DigitalTwinStateType = {
  isTwinReady: boolean
  isTwinLoading: boolean
  lastSelectedLayer: string | undefined
  loadingPercentage: number
  models: Array<models.ModelResponse> | undefined
};

const initialState: DigitalTwinStateType = {
  isTwinReady: false,
  isTwinLoading: false,
  lastSelectedLayer: undefined,
  loadingPercentage: 0,
  models: undefined,
};

export const DigitalTwinContext = createContext({
  state: initialState,
  dispatch: (() => { }) as ContextDispatch,
});

export const useDigitalTwin = (): [DigitalTwinStateType, ContextDispatch] => {
  const { state, dispatch } = useContext(DigitalTwinContext);
  return [state, dispatch];
};

export const DigitalTwinProvider:FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [state, dispatch] = useReducer(DigitalTwinReducer, initialState);

  return (
    <DigitalTwinContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </DigitalTwinContext.Provider>
  );
};
