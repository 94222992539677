import {
  Input, InputRef, Tag, Tooltip, message,
} from 'antd';
import React, { ChangeEvent, FC, useRef } from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import { notification } from '../../../antdProvider';
import styles from './styles.module.less';
import { Icon } from '..';

type TagInputProps = {
  className?: string
  style?: React.CSSProperties
  searchTags: string[]
  searchText: string
  addonAfter: JSX.Element | null
  onChangeTags: (value: string[]) => void
  onChangeText: (value: string) => void
};

const TagInput: FC<TagInputProps> = ({
  searchTags = [],
  searchText = '',
  addonAfter,
  onChangeText,
  onChangeTags,
  ...props
}) => {
  const inputRef = useRef<InputRef>(null);

  const handleDelete = (tag: string) => {
    const newArr = searchTags.filter(i => i !== tag);
    onChangeTags(newArr);
    message.destroy();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value.replace(/\t/g, '');
    onChangeText(text);
  };

  const handleOnChangeTags = () => {
    if (searchText !== '') {
      onChangeTags([...searchTags, searchText]);
    }
  };

  const handleBlur = () => {
    if (searchText) {
      if (searchTags.includes(searchText)) {
        notification.error({
          message: `${searchText} already exists`,
        });
        return;
      }
      handleOnChangeTags();
      onChangeText('');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') { e.preventDefault(); }
    if (e.key === 'Backspace' && !searchText) {
      onChangeTags(searchTags.slice(0, -1));
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && searchText) {
      if (searchTags.includes(searchText)) {
        notification.error({
          message: `${searchText} already exists`,
        });
        return;
      }
      handleOnChangeTags();
      onChangeText('');
    }
  };

  const handleClear = () => {
    onChangeTags([]);
    onChangeText('');
  };

  return (
    <div className={`${styles.inputFilter}`}>
      <div
        className={`
          ${styles.tagInput}
          ${searchTags.length && addonAfter ? styles.tagInputSquared : styles.tagInputRounded}`}
        onClick={() => inputRef.current?.focus()}
        {...props}
        role="button"
        tabIndex={0}
      >
        <div className={styles.iconContainer}>
          <Icon name="search" />
        </div>
        {searchTags.map(item => (item.length > 20 ? (
          <Tooltip title={item} key={item}>
            <Tag
              style={{ fontWeight: 'bold' }}
              closable
              onClose={() => handleDelete(item)}
            >
              {`${item.slice(0, 20)}...`}
            </Tag>
          </Tooltip>
        ) : (
          <Tag
            key={item}
            style={{ fontWeight: 'bold' }}
            closable
            onClose={() => handleDelete(item)}
          >
            {item}
          </Tag>
        )))}
        <Input
          ref={inputRef}
          variant="borderless"
          placeholder="Search"
          value={searchText}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          style={{ paddingLeft: 0 }}
        />
        {searchTags.length || searchText ? (
          <Tooltip title="clear">
            <div
              onClick={handleClear}
              role="button"
              tabIndex={0}
            >
              <CloseCircleFilled style={{ color: '#00000073', fontSize: 12, paddingRight: 12 }} />
            </div>
          </Tooltip>
        ) : null}
      </div>
      {searchTags.length > 1 ? addonAfter : null}
    </div>
  );
};

export default TagInput;
