import React from 'react';
import Highlighter from 'react-highlight-words';
import { getAssetManagerSearch } from '../../../selectors';
import { getContextStores } from '../../../context';

export interface Props {
  text: string
}

const SearchHighlight: React.FC<Props> = ({
  text,
}) => {
  const contextStores = getContextStores();
  const assetManagerSearch = getAssetManagerSearch(contextStores);

  return (
    <Highlighter
      searchWords={assetManagerSearch}
      textToHighlight={text || ''}
      sanitize={str => str.toString()?.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')}
    />
  );
};

export default SearchHighlight;
