import { createSelector } from 'reselect';
import { TwinData } from '../config/types';
import {
  getAssetsArray,
  getFilteredAssetsKeyedByStatusForDigitalTwin,
  getPlansKeyedByTwinId,
  getSelectedAssetType,
  getShowOnlySelectedAssets,
  getBandsKeyedById,
  getFilteredAssetKeyedByTwinId,
} from '.';
import map from './map';

export const getTwinIdsKeyedByAssetId = createSelector(
  [map.assetsKeyedById, getSelectedAssetType, getAssetsArray],
  (assetsKeyedById, selectedAssetType, assetArray) => {
    const { slots, modelIdsSlotIdReference } = selectedAssetType || {};

    if (modelIdsSlotIdReference) {
      const slotsKeyById = new Map(slots?.map(slot => [slot.id, slot]));
      const currentSlot = slotsKeyById.get(modelIdsSlotIdReference);
      const relationship = currentSlot?.relationship;

      const twinArray = assetArray
        .map((asset) => {
          const { id } = asset;
          const assetSlots = asset?.slots || {};
          const slot = assetSlots[relationship === 'has_many' ? 'children' : 'parents'];
          const assetIdsFromSlot = slot?.[modelIdsSlotIdReference];
          const twinFromSlot = typeof assetIdsFromSlot === 'string'
            ? [assetIdsFromSlot]
            : assetIdsFromSlot
              ?.map(assetId => assetsKeyedById.get(assetId)?.destination?.twinID || [])
              ?.flat();
          return { id, twinID: twinFromSlot };
        })
        .filter(({ twinID }) => twinID);

      return new Map(twinArray.map(twin => [twin.id, twin]));
    }

    const twinArray = assetArray
      .map((asset) => {
        const { destination, id } = asset;
        const twinID = destination?.twinID;
        return { id, twinID };
      })
      .filter(({ twinID }) => twinID);
    return new Map(twinArray.map(twin => [twin.id, twin]));
  },
);

export const getFilteredTwinData = createSelector(
  [
    getSelectedAssetType,
    getFilteredAssetsKeyedByStatusForDigitalTwin,
    map.lastFetchStartTime,
    getTwinIdsKeyedByAssetId,
  ],
  (
    selectedAssetType,
    filteredAssetsKeyedByStatus,
    lastFetchStartTime,
    twinIdsKeyedByAssetId,
  ): TwinData | undefined => {
    const { statuses, modelIdsSlotIdReference } = selectedAssetType;
    const hexColorsKeyedByTwinId: TwinData['hexColorsKeyedByTwinId'] = {};
    statuses?.forEach(({ id, color }) => {
      const assets = filteredAssetsKeyedByStatus.get(id);
      assets?.forEach((asset) => {
        const twinFromReference = modelIdsSlotIdReference
          ? twinIdsKeyedByAssetId.get(asset.id || '')?.twinID?.[0]
          : asset?.destination?.twinID?.[0];

        if (twinFromReference === undefined || !color) return;
        hexColorsKeyedByTwinId[twinFromReference] = color;
      });
    });

    return {
      hexColorsKeyedByTwinId,
      lastFetched: lastFetchStartTime,
    };
  },
);

export const getFilteredTwinPlan = createSelector(
  [
    getFilteredAssetKeyedByTwinId,
    getBandsKeyedById,
    getPlansKeyedByTwinId,
    map.lastFetchStartTime,
  ],
  (
    filteredAssetKeyedByTwinId,
    bandKeyedById,
    plansKeyedByTwinId,
    lastFetchStartTime,
  ): TwinData | undefined => {
    const hexColorsKeyedByTwinId = {};
    filteredAssetKeyedByTwinId.forEach((_, key) => {
      const bandID = plansKeyedByTwinId.get(key)?.result?.bandID || '';
      const band = bandKeyedById.get(bandID);
      hexColorsKeyedByTwinId[key] = band?.color || '#e1e1e1';
    });

    return {
      hexColorsKeyedByTwinId,
      lastFetched: lastFetchStartTime,
    };
  },
);

export const getAssetDetailsTwinData = createSelector(
  [
    getSelectedAssetType,
    map.assetDetailsAssetId,
    map.assetsKeyedById,
    map.lastFetchStartTime,
  ],
  (
    selectedAssetType,
    assetDetailsAssetId,
    assetsKeyedById,
    lastFetchStartTime,
  ): TwinData | undefined => {
    if (!assetDetailsAssetId) return undefined;

    const asset = assetsKeyedById.get(assetDetailsAssetId);
    const twinID = asset?.destination?.twinID?.[0];
    const assetStatus = selectedAssetType?.statuses?.find(s => s.id === asset?.status);
    if (twinID === undefined || !assetStatus?.color) return undefined;
    const hexColorsKeyedByTwinId = { [twinID]: assetStatus?.color };

    return {
      hexColorsKeyedByTwinId,
      lastFetched: lastFetchStartTime,
    };
  },
);

export const getSelectedTwinIds = createSelector(
  [getShowOnlySelectedAssets, getTwinIdsKeyedByAssetId],
  (selectedAssets, twinIdsKeyedByAssetId) => selectedAssets
    .map(selected => twinIdsKeyedByAssetId.get(selected?.id)?.twinID?.[0])
    .filter(twin => !!twin) as Array<string>,
);
