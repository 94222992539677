import { createSelector } from 'reselect';
import { AssetTableColumn } from '../config/types';
import { getAssetManagerFilters, getAssetManagerTwinPlanId } from '.';
import map from './map';
import { DEFAULT_COLUMNS, PLAN_COLUMNS } from '../config/constants';

export const getListViewByAssetManagerId = createSelector(
  [map.features],
  (features): Map<string, { [key: string]: Array<AssetTableColumn> }> => {
    type AssetManagerMap = Map<string, { [key: string]: Array<AssetTableColumn> }>;
    const listViewByAssetManagerId:AssetManagerMap = new Map();

    if (features) {
      const featuresKeys = Object.keys(features);
      featuresKeys?.forEach((key) => {
        listViewByAssetManagerId.set(key, features[key]?.list_view);
      });
    }
    return listViewByAssetManagerId;
  },
);

export const getListViewColumns = createSelector([
  map.assetManagerId,
  getListViewByAssetManagerId,
  getAssetManagerFilters,
  getAssetManagerTwinPlanId,
], (
  assetManagerId,
  listViewByAssetManagerId,
  assetManagerFilters,
  twinPlanId,
): Array<AssetTableColumn> => {
  const currentFeatureListViews = listViewByAssetManagerId.get(assetManagerId) || {};
  const { typeFilter } = assetManagerFilters;
  let listView = currentFeatureListViews[typeFilter];

  if (!listViewByAssetManagerId || !listView || !typeFilter) {
    return DEFAULT_COLUMNS;
  }

  if (assetManagerId === 'status_tracking') {
    listView[0] = { ...listView[0], fixed: 'left' };
  }

  const statusPosition = listView.find(
    ({ key }) => key === 'statusUpdatedDate',
  )?.column_order.full_view;

  if (twinPlanId && statusPosition) {
    listView = [
      ...listView.slice(0, statusPosition),
      ...PLAN_COLUMNS,
      ...listView.slice(statusPosition),
    ].map((item, index) => ({
      ...item,
      column_order: {
        full_view: index,
        small_view: index,
      },
    }));
  }

  return listView;
});

export const getColumnsKeyedById = createSelector([getListViewColumns], listViewColumns => new Map(
  listViewColumns.map(column => [column.key, column]),
) as Map<string, AssetTableColumn>);

export const getCustomColumns = createSelector(
  [getListViewColumns],
  listViewColumns => listViewColumns.filter(({ custom }) => custom),
);
