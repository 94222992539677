import React from 'react';
import Down from '../../../images/down.svg?react';
import Up from '../../../images/up.svg?react';
import CheckBlack from '../../../images/check-black.svg?react';
import CheckWhite from '../../../images/check-white.svg?react';
import ClosedBlack from '../../../images/close-black.svg?react';
import ClosedWhite from '../../../images/close-white.svg?react';
import YnomiaFullLogoDark from '../../../images/ynomia-full-logo-dark.svg?react';
import Close from '../../../images/close.svg?react';
import Menu from '../../../images/menu.svg?react';
import DoubleLeftOutlined from '../../../images/double-left-outlined.svg?react';
import DoubleRightOutlined from '../../../images/double-right-outlined.svg?react';
import Search from '../../../images/search.svg?react';
import Clear from '../../../images/clear.svg?react';
import Location from '../../../images/building.svg?react';
import Supplier from '../../../images/factory.svg?react';
import Status from '../../../images/status.svg?react';
import Action from '../../../images/action.svg?react';
import Stopwatch from '../../../images/stopwatch.svg?react';
import CloseBlue from '../../../images/close-blue.svg?react';
import PresenterMode from '../../../images/presenter-mode.svg?react';
import Export from '../../../images/export-black.svg?react';
import AssetTable from '../../../images/asset-table.svg?react';
import DigitalTwinAssetTable from '../../../images/digital-twin-asset-table.svg?react';
import DigitalTwin from '../../../images/digital-twin.svg?react';
import MultiSelect from '../../../images/multi-select.svg?react';
import YnomiaIconLight from '../../../images/ynomia-icon-light.svg?react';

type MyIconProps = {
  name: 'down'
  | 'up'
  | 'checkBlack'
  | 'checkWhite'
  | 'closedBlack'
  | 'closedWhite'
  | 'ynomiaFullLogoDark'
  | 'close'
  | 'menu'
  | 'doubleLeftOutlined'
  | 'doubleRightOutlined'
  | 'search'
  | 'clear'
  | 'location'
  | 'supplier'
  | 'status'
  | 'action'
  | 'stopwatch'
  | 'closeBlue'
  | 'presenterMode'
  | 'assetTable'
  | 'digitalTwinAssetTable'
  | 'digitalTwin'
  | 'multiSelect'
  | 'ynomiaLight'
  | 'export';
  id?: string
  className?: any;
  style?: any;
  height?: string | number;
  width?: string | number;
  onClick?: () => void;
};

const Icon = (props: MyIconProps) => {
  const { name, ...svgProps } = props;
  const Icons: Record<MyIconProps['name'], any> = {
    down: <Down {...svgProps} />,
    up: <Up {...svgProps} />,
    checkBlack: <CheckBlack {...svgProps} />,
    checkWhite: <CheckWhite {...svgProps} />,
    search: <Search {...svgProps} />,
    status: <Status {...svgProps} />,
    action: <Action {...svgProps} />,
    doubleRightOutlined: <DoubleRightOutlined {...svgProps} />,
    clear: <Clear {...svgProps} />,
    location: <Location {...svgProps} />,
    supplier: <Supplier {...svgProps} />,
    stopwatch: <Stopwatch {...svgProps} />,
    closeBlue: <CloseBlue {...svgProps} />,
    presenterMode: <PresenterMode {...svgProps} />,
    export: <Export {...svgProps} />,
    closedBlack: <ClosedBlack {...svgProps} />,
    closedWhite: <ClosedWhite {...svgProps} />,
    ynomiaFullLogoDark: <YnomiaFullLogoDark {...svgProps} />,
    close: <Close {...svgProps} />,
    menu: <Menu {...svgProps} />,
    doubleLeftOutlined: <DoubleLeftOutlined {...svgProps} />,
    assetTable: <AssetTable {...svgProps} />,
    digitalTwinAssetTable: <DigitalTwinAssetTable {...svgProps} />,
    digitalTwin: <DigitalTwin {...svgProps} />,
    multiSelect: <MultiSelect {...svgProps} />,
    ynomiaLight: <YnomiaIconLight {...svgProps} />,
  };

  return Icons[name];
};

export default Icon;
