import { createSelector } from 'reselect';
import { roundValue } from '@ynomia/core/dist/utils';
import { getAssetManagerFilters, getObservationTypes } from '..';
import { getFilteredAssets, getFilteredAssetsContext } from './index';
import { ObservationSummaryConfig } from '../../config/types';
import map from '../map';

const getFilteredAssetsForObservationSummary = createSelector(
  [
    getAssetManagerFilters,
    getFilteredAssetsContext,
  ],
  (
    assetManagerFilters,
    filteredAssetsContext,
  ) => {
    const {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      selectedLayerIds,
      assetStatusFilter,
      selectedBandIds,
      tableFilter,
      openedLayerId,
      multiSearchModeFilter,
      fieldFilters,
    } = assetManagerFilters;
    const filteredAssets = getFilteredAssets(filteredAssetsContext, {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      selectedBandIds,
      selectedLayerIds,
      assetStatusFilter,
      tableFilter,
      openedLayerId,
      multiSearchModeFilter,
      fieldFilters,
    });
    return filteredAssets.sort((a, b) => String(a.label).localeCompare(String(b.label)));
  },
);

export const getObservationSummaryConfigByAssetManagerId = createSelector(
  [map.features],
  (features): Map<string, { [key: string]: ObservationSummaryConfig }> => {
    type ObservationSummaryConfigMap = Map<string, { [key: string]: ObservationSummaryConfig }>;
    const observationSummaryByFeature: ObservationSummaryConfigMap = new Map();

    if (features) {
      const featuresKeys = Object.keys(features);
      featuresKeys?.forEach((key) => {
        observationSummaryByFeature.set(key, features[key]?.observation_summary);
      });
    }
    return observationSummaryByFeature;
  },
);

const getObservationsByObservationTypes = createSelector(
  [getFilteredAssetsForObservationSummary],
  (filteredAssetsForObservationSummary) => {
    const assetsByObservationType: Map<string, Array<string>> = new Map();

    filteredAssetsForObservationSummary.forEach((asset) => {
      const recordedTypes = new Set<string>();
      asset.observations?.forEach(({ type }) => {
        // Do not count same obs type multiple times
        if (recordedTypes.has(type)) return;
        recordedTypes.add(type);
        const assetList = assetsByObservationType.get(type);
        if (assetList) {
          assetList.push(asset.id);
        } else {
          assetsByObservationType.set(type, [asset.id]);
        }
      });
    });
    return assetsByObservationType;
  },
);

export const getObservationSummaryConfig = createSelector(
  [
    map.assetManagerId,
    getObservationSummaryConfigByAssetManagerId,
    getAssetManagerFilters,
  ],
  (
    assetManagerId,
    observationSummaryByFeature,
    assetManagerFilters,
  ) => {
    const { typeFilter } = assetManagerFilters;
    const config = observationSummaryByFeature.get(assetManagerId);
    if (!config) return undefined;
    return config[typeFilter];
  },
);

export const getObservationSummaryTitle = createSelector(
  [getObservationSummaryConfig],
  observationSummaryConfig => observationSummaryConfig?.title || 'Actions',
);

export const getObservationSummary = createSelector(
  [
    getObservationTypes,
    getFilteredAssetsForObservationSummary,
    getObservationsByObservationTypes,
    getObservationSummaryConfig,
  ],
  (
    observationTypes,
    filteredAssetsForObservationSummary,
    observationsByObservationTypes,
    observationSummaryTypes,
  ) => {
    const totalObservationAssets = filteredAssetsForObservationSummary.length;

    return observationTypes
      .filter(o => (!observationSummaryTypes?.observationTypes
        || observationSummaryTypes.observationTypes.includes(o.id)))
      .map((observationType) => {
        const { id } = observationType;
        const withOccurrence = observationsByObservationTypes.get(id)?.length || 0;
        const withPercentage = roundValue(
          (withOccurrence * 100) / totalObservationAssets,
          1,
        );

        const withoutOccurrence = totalObservationAssets - withOccurrence;
        const withoutPercentage = roundValue(100 - withPercentage, 1);
        return {
          ...observationType,
          withOccurrence,
          withPercentage,
          withoutOccurrence,
          withoutPercentage,
        };
      });
  },
);
