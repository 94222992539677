import type { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import React, {
  createContext, FC, useContext, useReducer,
} from 'react';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import {
  AssetStatusFilter, ContextDispatch, DataType, ToggleObservation,
} from '../config/types';
import FilterReducer from '../reducers/assetManager';
import { anyFilterIsActive } from '../utils/assetFilters';
import { getAssetTypes } from '../selectors';
import { getContextStores } from '.';

export type AssetFilter = {
  searchTags: Array<string>
  searchText: string,
  dateFilter: [Dayjs, Dayjs] | []
  typeFilter: string
  assetStatusFilter: AssetStatusFilter
  observationTypeFilter: Record<string, ToggleObservation>,
  multiSearchModeFilter: 'or' | 'and'
  selectedLayerIds: Array<string>
  openedLayerId: string | null
  tableFilter: Record<string, FilterValue | null>
  tableSorter: SorterResult<DataType> | null
  pagination: TablePaginationConfig
  fieldFilters: { [key: string]: Array<string> }
  timeTravelDate: Dayjs | null
  selectedBandIds: Array<string>
};

export type AssetSelection = {
  lastManualSelectionUpdate: Date | undefined
  selectedAssets: Array<string>
  showSelectedOnly: boolean
};

export type AssetManagerStateType = {
  [id: string]: {
    filters: AssetFilter
    assetSelection: AssetSelection
    collapsable?: Record<string, { isCollapsed?: boolean, showAll?: boolean }>
    twinPlanId: string | null
  }
};

const urlParams = new URLSearchParams(window.location.search);
const getUrlJsonParam = (key: string) => {
  const encodedData = urlParams.get(key);
  if (!encodedData) return undefined;
  const jsonString = decodeURIComponent(encodedData);
  const custom = (k: string, v: any) => {
    switch (k) {
      case 'dateFilter':
        return (v[0] && v[1]) ? [dayjs(v[0]), dayjs(v[1])] : [];
      case 'timeTravelDate':
        return v && dayjs(v);
      default:
        return v;
    }
  };
  const data = JSON.parse(jsonString, custom) as AssetManagerStateType;

  return data;
};

const initialState: AssetManagerStateType = getUrlJsonParam('assetManagerState') || {};

export const AssetManagerContext = createContext({
  state: initialState,
  dispatch: (() => { }) as ContextDispatch,
});

export const useAssetManager = (): [AssetManagerStateType, ContextDispatch] => {
  const { state, dispatch } = useContext(AssetManagerContext);
  return [state, dispatch];
};

export const AssetManagerProvider:FC<React.PropsWithChildren<{}>> = ({ children }) => {
  /* Context  */
  const contextStores = getContextStores();
  const [state, dispatch] = useReducer(FilterReducer, initialState);
  const assetTypes = getAssetTypes(contextStores);
  const defaultAssetType = assetTypes?.[0]?.id;

  const generatePrunedClone = () => {
    const clone = JSON.parse(JSON.stringify(state)) as AssetManagerStateType;
    Object.keys(clone).forEach((k) => {
      // @ts-ignore
      delete clone[k].assetSelection;

      if (
        clone[k].filters
        && !anyFilterIsActive(clone[k].filters, defaultAssetType)
      ) {
        // @ts-ignore
        delete clone[k].filters;
      }

      const noCollaspableActive = Object
        // @ts-ignore
        .values(clone[k].collapsable || {})
        .every(v => v.isCollapsed === false && v.showAll === false);
      if (noCollaspableActive) delete clone[k].collapsable;

      if (isEmpty(clone[k])) delete clone[k];
    });
    return clone;
  };

  const setAssetManagerSearchParams = () => {
    const url = new URL(window.location.href);
    const clone = generatePrunedClone();
    if (isEmpty(clone)) {
      url.searchParams.delete('assetManagerState');
    } else {
      url.searchParams.set('assetManagerState', encodeURIComponent(JSON.stringify(clone)));
    }

    window.history.replaceState(null, '', url);
  };

  setAssetManagerSearchParams();
  return (
    <AssetManagerContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </AssetManagerContext.Provider>
  );
};
