import 'react-circular-progressbar/dist/styles.css';
import React, { Component } from 'react';
import { Button } from 'antd';
import { CircularProgressbar } from 'react-circular-progressbar';
import isEqual from 'lodash/isEqual';
import { TwinConfig } from '@ynomia/core/dist/blueprint';
import { ACCESS_TOKEN_STORAGE_KEY } from '../../../config/constants';
import { TwinData } from '../../../config/types';
import { calculateLoadingPercentage } from '../../../utils/modelViewing';
import { notification } from '../../../antdProvider';
import styles from './styles.module.less';

interface Props {
  source: string
  modelCode: string
  mappingModelId?: string
  twinData: TwinData | undefined
  config: string
  loadModels: Array<string>
  project: string
  tenant: string,
  isTwinLoading: boolean
  selectedTwinIds?: Array<string>
  forgeConfig: TwinConfig['forge'],
  /**
   * Update this date every time we want the twin to update.
   * We are handling this manually to prevent an infinite loop from happening
   * where the twin sends different data to Web at the same time as Web sends
   * data to the twin.
   * Don't update this if the update is triggered by the twin.
   * ie. If the user selects from the twin and web's selection gets update.
   */
  lastExternalUpdate?: Date
  requestModelMapping?: string
  mappingIgnoreArray?: Array<{ key: string, value: string }>
  mapLargestElementOnly?: boolean
  mappingOverride?: { [twinID: string]: Array<number> }
  assetDetailsAssetId?: null | string
  mappingTwinIds?: Array<string>
  // Update the date to force requestFitToView
  requestFitToView?: Date,
  onTwinReady?: (boolean, coordinates: { x: number, y: number, z: number }) => void
  onTwinLoading?: (number) => void
  onTwinCancel?: () => void
  onMapProgress?: (progress: number) => void
  onMapComplete?: (mapping: { [twinID: string]: Array<number> }) => void
  onSelectTwin?: (twinID: Array<string>, isMultiSelect: boolean) => void
  onHoverTwin?: (ev: {
    twinId: string | undefined,
    clientX: number,
    clientY: number
  }) => void
  presenterIdle?: boolean
}

interface State {
  modelByKeys: { [id: string]: { id: string, loading: number } };
  lastTwinInteraction: Date,
  // This represents the twinIDs that are currently selected in the model (iFrame)
  // To be used to detect if the model is still syncing with Web.
  modelSelectedTwinIds: Array<string>;
  // Some selected twinIds are not present in the model
  selectedTwinIdsNotInTwin: Array<string>;
}

export default class ModelViewer extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      modelByKeys: {},
      lastTwinInteraction: new Date(),
      modelSelectedTwinIds: [],
      selectedTwinIdsNotInTwin: [],
    };

    this.viewerRef = React.createRef();
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }

  componentDidMount() {
    window.addEventListener('message', this.onMessage.bind(this), false);
    const { onTwinLoading } = this.props;
    if (onTwinLoading) {
      onTwinLoading(0);
    }
    if ('Notification' in window && !this.isMobile) {
      Notification.requestPermission();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      twinData,
      mappingModelId,
      loadModels,
      onTwinLoading,
      selectedTwinIds,
      assetDetailsAssetId,
      requestFitToView,
      presenterIdle,
      isTwinLoading,
    } = this.props;

    if (prevProps.presenterIdle !== presenterIdle) {
      if (presenterIdle) {
        this.postMessage(['hideUI']);
      } else {
        this.postMessage(['showUI']);
      }
    }

    // This is a workaround for the race condition where the twin isn't painted properly on load
    if (isTwinLoading) {
      const { selectedTwinIdsInTwin } = this.getSelectedTwinIds();
      this.postMessageWithData(twinData, selectedTwinIdsInTwin);
    }

    if (!isEqual(prevProps.twinData?.hexColorsKeyedByTwinId, twinData?.hexColorsKeyedByTwinId)
      || !isEqual(prevProps.selectedTwinIds, selectedTwinIds)
    ) {
      const { selectedTwinIdsNotInTwin, selectedTwinIdsInTwin } = this.getSelectedTwinIds();
      this.setState({ selectedTwinIdsNotInTwin });
      this.postMessageWithData(twinData, selectedTwinIdsInTwin);
    }

    if ((loadModels.toString() !== prevProps.loadModels.toString())) {
      if (onTwinLoading) {
        onTwinLoading(0);
      }
    }

    if (
      this.props.requestModelMapping
      && (this.props.requestModelMapping !== prevProps.requestModelMapping)
    ) {
      this.postMessage([
        'mapModel', [
          mappingModelId,
          this.props.requestModelMapping,
          this.props.mappingIgnoreArray,
          this.props.mapLargestElementOnly,
        ],
      ]);
    }

    if (this.props.mappingOverride && (this.props.mappingOverride !== prevProps.mappingOverride)) {
      this.postMessage([
        'overrideMapping', [
          mappingModelId,
          this.props.mappingOverride,
        ],
      ]);
      this.postMessageWithData(twinData, selectedTwinIds || []);
    }

    if (
      // When user switches between asset details, we want to zoom into the appropriate element
      assetDetailsAssetId !== prevProps.assetDetailsAssetId
      || requestFitToView?.getTime() !== prevProps.requestFitToView?.getTime()
    ) {
      // Always request fit to view after to prevent race conditions.
      // This also waits for fast animations/resizing of the twin to complete.
      setTimeout(() => this.postMessage(['requestFitToView']), 200);
    }

    // When the twin loads before the assets load,
    // we want to request fit to view once the data rolls in
    if (prevProps.twinData?.lastFetched === null && twinData?.lastFetched !== null) {
      this.postMessage(['requestFitToView']);
    }

    // Reselect selected item when exiting from asset details
    if (
      assetDetailsAssetId !== prevProps.assetDetailsAssetId
      && !assetDetailsAssetId
    ) {
      this.postMessage(['setSelectedTwinIds', selectedTwinIds]);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onMessage.bind(this), false);
  }

  viewerRef: React.RefObject<HTMLIFrameElement>;

  isMobile: boolean;

  postMessage = (message) => {
    this.viewerRef?.current?.contentWindow?.postMessage(message, '*');
  };

  getSelectedTwinIds = () => {
    const { selectedTwinIds, mappingTwinIds = [] } = this.props;
    const selectedTwinIdsNotInTwin: Array<string> = [];
    const selectedTwinIdsInTwin: Array<string> = [];
    selectedTwinIds?.forEach((twinID) => {
      if (!mappingTwinIds.includes(twinID)) {
        selectedTwinIdsNotInTwin.push(twinID);
      } else {
        selectedTwinIdsInTwin.push(twinID);
      }
    });
    return {
      selectedTwinIdsNotInTwin,
      selectedTwinIdsInTwin,
    };
  };

  postMessageWithData = (twinData: TwinData | undefined, selectedTwinIds: Array<string>) => {
    const { modelCode, config } = this.props;
    this.postMessage(['setColorConfig', config]);
    this.postMessage(['updateColorsByTwinID', [twinData?.hexColorsKeyedByTwinId, modelCode]]);
    if (this.getUpdatePriority() === 'web') {
      this.postMessage(['setSelectedTwinIds', selectedTwinIds]);
    }
  };

  modelSelectedTwinIdsInSync = () => {
    const { selectedTwinIds, assetDetailsAssetId, twinData } = this.props;
    const { modelSelectedTwinIds, selectedTwinIdsNotInTwin } = this.state;

    // Don't care about sync if no "selectedTwinIds" prop is provided
    if (!selectedTwinIds) return true;

    // We don't care if it's in sync or not when in the asset details screen
    if (assetDetailsAssetId) return true;
    const a = Array.from(new Set(modelSelectedTwinIds))
      .concat(selectedTwinIdsNotInTwin)
      // Do not compare twinIds that we do not have an asset for
      .filter(twinId => twinData === undefined || !!twinData.hexColorsKeyedByTwinId[twinId])
      .sort();
    const b = Array.from(new Set(selectedTwinIds)).sort();
    return isEqual(a, b);
  };

  getLoadingPercentage = () => {
    const { modelByKeys } = this.state;
    const { loadModels } = this.props;
    const calculated = calculateLoadingPercentage(modelByKeys, loadModels);
    return Math.min(calculated, 99);
  };

  onMessage = (event) => {
    const functionName = event.data[0];
    const { onTwinReady, onTwinLoading } = this.props;
    const { modelByKeys } = this.state;

    if (functionName === 'onTwinProgress') {
      const { data } = event;
      const id = data[1];
      const percentage = data[2];
      const newModelByKeys = {
        ...modelByKeys,
        [id]: {
          id,
          loading: percentage,
        },
      };
      this.setState({
        modelByKeys: newModelByKeys,
      }, () => {
        if (onTwinLoading) {
          onTwinLoading(this.getLoadingPercentage());
        }
      });
    }
    if (functionName === 'onTwinReady' && onTwinReady) {
      onTwinReady(true, event?.data?.[1]);

      if (onTwinLoading) {
        onTwinLoading(100);
      }

      if (!document.hasFocus() && !this.isMobile && ('Notification' in window)) {
        // eslint-disable-next-line no-new
        new Notification('Digital twins is ready for viewing', {
          icon: '/src/favicon.png',
        });
      }

      notification.success({
        key: 'digital_twin_success',
        message: 'Digital twin is ready for viewing',
      });
    }

    if (functionName === 'onMapProgress') {
      this.props.onMapProgress?.(event.data[1]);
    }

    if (functionName === 'onMapComplete') {
      this.props.onMapComplete?.(event.data[1]);
    }

    if (this.registeredFunctions[functionName]) {
      const args = event.data.slice(1);
      this.registeredFunctions[functionName](...(args || {}));
    }
  };

  registeredFunctions = {
    getData: () => {
      const { twinData, selectedTwinIds } = this.props;
      this.postMessageWithData(twinData, selectedTwinIds || []);
    },
    getSelectedTwinIds: (rawTwinIds: string) => {
      const { modelSelectedTwinIds, selectedTwinIdsNotInTwin } = this.state;
      const {
        twinIds,
        isDoubleClick,
      } = JSON.parse(rawTwinIds) as {
        twinIds: Array<string>, boxSelectionActive: boolean, isDoubleClick: boolean
      };
      this.setState(() => ({ modelSelectedTwinIds: twinIds }));
      const isMultiSelect = !isDoubleClick;

      if (isEqual([...modelSelectedTwinIds].sort(), [...twinIds].sort())) {
        // Still update parent if it's a double click
        if (isDoubleClick) {
          this.props.onSelectTwin?.(twinIds, isMultiSelect);
        }
        return;
      }

      if (this.getUpdatePriority() === 'twin') {
        this.props.onSelectTwin?.([...twinIds, ...selectedTwinIdsNotInTwin], isMultiSelect);
      }
    },
    elementHovered: (twinId: {
      twinId: string | undefined,
      clientX: number,
      clientY: number
    }) => {
      this.props.onHoverTwin?.(twinId);
    },
  };

  /*
   * The twin does not distinguish programmatically made selection
   * changes and user twin interactions, meaning that an alternate
   * method needs to be used to determine which user is interacting.
   * In this implementation, the user is considered to be interacting
   * with the twin if their mouse had entered the twin sooner than
   * the last confirmed manual update.
   */
  getUpdatePriority = () => {
    const { lastTwinInteraction } = this.state;
    const { lastExternalUpdate, assetDetailsAssetId } = this.props;
    if (assetDetailsAssetId) return 'web';
    if (lastExternalUpdate && (lastExternalUpdate > lastTwinInteraction)) {
      return 'web';
    }
    return 'twin';
  };

  render() {
    const {
      source, project, tenant, loadModels, isTwinLoading, presenterIdle, forgeConfig,
    } = this.props;
    const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
    const forgeConfigStr = encodeURIComponent(forgeConfig ? JSON.stringify(forgeConfig) : '{}');
    const urlParams = `\
accessToken=${accessToken}\
&project=${project}&tenant=${tenant}\
&loadModels=${loadModels}\
&forgeConfig=${forgeConfigStr}`;
    return (
      <>
        {isTwinLoading
          && (
          <div className={styles.loadingContainer}>
            <div className={styles.loadingText}>
              <div>Digital Twin Loading...</div>
              <div>We will notify you when ready</div>
            </div>
            <div style={{ width: 90, height: 90 }}>
              <CircularProgressbar
                value={this.getLoadingPercentage()}
                text={`${this.getLoadingPercentage()}%`}
              />
            </div>
            <Button
              style={{ marginTop: 50 }}
              onClick={this.props.onTwinCancel}
            >
              Cancel
            </Button>
          </div>
          )}
        <div
          className={styles.syncingContainer}
          style={
            this.modelSelectedTwinIdsInSync()
              ? { opacity: 0, pointerEvents: 'none' }
              : { opacity: 0.5 }
          }
        />
        <iframe
          onMouseEnter={() => {
            this.setState({ lastTwinInteraction: new Date() });
          }}
          onMouseLeave={() => {
            this.props.onHoverTwin?.({ twinId: undefined, clientX: 0, clientY: 0 });
          }}
          ref={this.viewerRef}
          src={`${source}/?${urlParams}`}
          allowFullScreen
          frameBorder="0"
          title="Twin"
          style={{
            minWidth: '300px',
            width: '100%',
            height: '100%',
            flex: 1,
            margin: '0 auto',
            position: 'absolute',
            borderRadius: 4,
            visibility: isTwinLoading ? 'hidden' : 'visible',
            pointerEvents: presenterIdle ? 'none' : 'auto',
          }}
          onLoad={() => {
            this.postMessage(['updateToolbar', '']);
          }}
        />
      </>
    );
  }
}
