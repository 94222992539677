import { isEmpty } from 'lodash';
import { AssetFilter } from '../context';

export const assetFilterIsActive = (assetManagerFilters: AssetFilter) => {
  const {
    assetStatusFilter,
    tableFilter,
    openedLayerId,
    selectedLayerIds,
    fieldFilters,
    observationTypeFilter,
    dateFilter,
    selectedBandIds,
  } = assetManagerFilters;
  return !!(
    assetStatusFilter?.toggle
    || !Object.values(tableFilter).every(element => element === null || !element.length)
    || openedLayerId
    || selectedLayerIds?.length
    || !isEmpty(fieldFilters)
    || !isEmpty(observationTypeFilter)
    || dateFilter[0] || dateFilter[1]
    || selectedBandIds?.length
  );
};

export const anyFilterIsActive = (assetManagerFilters: AssetFilter, defaultAssetType?: string) => {
  const {
    typeFilter,
    fieldFilters,
    timeTravelDate,
    pagination,
    tableSorter,
  } = assetManagerFilters;
  return !!(
    assetFilterIsActive(assetManagerFilters)
    || (typeFilter !== defaultAssetType)
    || !isEmpty(fieldFilters)
    || timeTravelDate
    || tableSorter?.order
    || pagination?.current !== 1
    || pagination?.pageSize !== 20
  );
};
