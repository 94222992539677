import { models } from '@ynomia/core';
import {
  SET_MODELS,
  SET_IS_LOADING_PERCENTAGE,
  SET_IS_TWIN_LOADING,
  SET_IS_TWIN_READY,
  SET_LAST_SELECTED_LAYER,
} from './actionTypes';
import { ContextDispatch } from '../config/types';
import { fetchModels } from '../services/digitalTwin';

export const setIsTwinReady = async (
  isTwinReady: boolean,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_IS_TWIN_READY,
    payload: isTwinReady,
  });
};

export const setIsTwinLoading = async (
  isTwinLoading: boolean,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_IS_TWIN_LOADING,
    payload: isTwinLoading,
  });
};

export const setLastSelectedLayer = async (
  lastSelectedLayer: string | undefined,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_LAST_SELECTED_LAYER,
    payload: lastSelectedLayer,
  });
};

export const setIsLoadingPercentage = (
  loadingPercentage: number,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_IS_LOADING_PERCENTAGE,
    payload: loadingPercentage,
  });
};

export const setModels = (
  modelsData: Array<models.Model>,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_MODELS,
    payload: modelsData,
  });
};

export const getModels = async (
  tenant: string,
  projectCode: string,
  dispatch: ContextDispatch,
) => {
  // We only care about active models
  const modelsData = await fetchModels(
    tenant,
    projectCode,
    {
      activeOnly: true,
      includeMapping: true,
    },
  );
  setModels(modelsData, dispatch);
};
