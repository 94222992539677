import {
  AssetType, Project, ProjectSummary, RoleType,
  AssetStatus,
} from '@ynomia/core/dist/blueprint';
import { ReportConfig } from '@ynomia/core/dist/report_v2';
import { UserInfo } from '@ynomia/client';
import { report as coreReport } from '@ynomia/core';
import { createSelector } from 'reselect';
import map from './map';

/**
 * Returns scratchProjectCode as a string.
 */
export const getScratchProjectCode = createSelector(
  [map.scratchProjectCode],
  scratchProjectCode => scratchProjectCode || '' as string,
);

/**
 * Returns scratchProjectCode as a string.
 */
export const getManagedRoles = createSelector(
  [map.managedRoles],
  managedRoles => managedRoles || [] as Array<string>,
);

/**
 * Returns project Id as a string.
 */
export const getProjectId = createSelector(
  [map.projectId],
  projectId => projectId || '' as string,
);

/**
 * Returns project as an object.
 */
export const getProject = createSelector(
  [map.project],
  project => project || {} as Project,
);

/**
 * Returns projects as an array.
 */
export const getProjects = createSelector(
  [map.projects],
  projects => projects || [] as Array<ProjectSummary>,
);

/**
 * Returns projects as an array.
 */
export const getProjectsByTenant = createSelector(
  [map.projects],
  (projects) => {
    const projectsByTenant = new Map();
    if (!projects) return projectsByTenant;

    const tenants = projects?.map(({ tenant }) => tenant);
    tenants.forEach((tenant) => {
      const currentProjects = projects.filter(
        project => project.tenant === tenant,
      );
      projectsByTenant.set(tenant, currentProjects);
    });

    return projectsByTenant;
  },
);

/**
 * Returns project name as a string.
 */
export const getProjectName = createSelector(
  [map.projectName],
  projectName => projectName || '' as string,
);

/**
 * Returns tenant as a string.
 */
export const getTenant = createSelector(
  [map.tenant],
  tenant => tenant || '' as string,
);

/**
 * Returns user info as a object.
 */
export const getUserInfo = createSelector(
  [map.userInfo],
  userInfo => userInfo || {} as UserInfo,
);

/**
 * Retrieves the different types of asset categories available for the project. This is denoted
 * with the `"managed" === true` flag on the asset type. We also make sure that the current user
 * only sees the asset types that they have access to.
 */

export const getAssetTypesByFeature = createSelector(
  [map.assetTypes, map.features],
  (assetTypes, features) => {
    if (!assetTypes || !features) {
      return [];
    }
    const featureKeys = Object.keys(features);
    const assetTypesByFeature = {};
    featureKeys.forEach((featureKey) => {
      const managedAssetTypes = assetTypes.filter(at => at.managed);
      const currentAssetTypes = features[featureKey]?.list_view;
      /**
       * @TODO
       * We shouldn't be relying on the list_view to determine which asset type to show.
       * It should be a much more generic flag against the feature.
       * */
      if (!currentAssetTypes) return;
      assetTypesByFeature[featureKey] = managedAssetTypes
        .filter(name => Object.keys(currentAssetTypes).includes(name.id));
    });
    return assetTypesByFeature;
  },
);

export const getAssetTypes = createSelector(
  [
    map.assetTypes,
    map.assetManagerId,
    getAssetTypesByFeature,
  ],
  (assetTypes, assetManagerId, assetTypesByFeature): Array<AssetType> => {
    const managedAssetTypes = assetTypes?.filter(at => at.managed);
    const featureAssetTypes = assetTypesByFeature[assetManagerId];
    return featureAssetTypes ?? managedAssetTypes;
  },
);

export const getAssetTypesById = createSelector(
  [getAssetTypes],
  assetTypes => new Map(
    assetTypes.map(assetType => [assetType.type, assetType]),
  ) as Map<string, AssetType>,
);

/**
 * Keys all known asset statuses for the project by their ID (irrespective of asset type).
 */
export const getAssetStatusesKeyedById = createSelector(
  [getAssetTypes],
  (assetTypes) => {
    const statusesFromAssetTypes = assetTypes.map(({ statuses }) => statuses).flat();
    return new Map(
      statusesFromAssetTypes.map(status => [status?.id, status]),
    ) as Map<string, AssetStatus>;
  },
);

export const getDefaultAssetStatus = createSelector(
  [getAssetStatusesKeyedById],
  assetStatusesKeyedById => [...assetStatusesKeyedById.values()].find(s => s.default),
);

export const getReportGroupsArray = createSelector(
  [map.reports],
  (reports) => {
    if (!reports) {
      return [];
    }
    const reportTypes: Array<string> = reports?.map(report => report.group);
    return [...new Set(reportTypes)] as Array<string>;
  },
);

export const getReportKeyedByAssetTypes = createSelector(
  [map.reports, getAssetTypesById],
  (reports, assetTypesById) => {
    const assetTypeIds = [...assetTypesById.keys()];

    const reportsKeyedByFilterAssetTypes = new Map();

    if (reports) {
      assetTypeIds.forEach((id) => {
        const filteredReports = reports.filter(
          report => report?.filters?.asset_types?.includes(`${id}`),
        );
        reportsKeyedByFilterAssetTypes.set(id, filteredReports);
      });
    }

    return reportsKeyedByFilterAssetTypes as Map<string, Array<ReportConfig>>;
  },
);

export const getReportKeyedByGroups = createSelector(
  [map.reports, getReportGroupsArray],
  (reports, reportGroupsArray) => {
    const reportsKeyedByType = new Map();

    if (reports) {
      reportGroupsArray.forEach((id) => {
        const reportsFilteredByGroup = reports.filter(({ group }) => group === id);
        reportsKeyedByType.set(id, reportsFilteredByGroup);
      });
    }

    return reportsKeyedByType as Map<string, Array<coreReport.Report>>;
  },
);

export const getFeatures = createSelector(
  [map.features],
  features => features || {},
);

export const getCurrentFeatureFlags = createSelector(
  [
    map.features,
    map.assetManagerId,
  ],
  (
    features,
    assetManagerId,
  ) => features?.[assetManagerId]?.flags ?? {},
);

export const checkFeatureTimeTravelControl = createSelector(
  [getCurrentFeatureFlags],
  flags => !!flags?.time_travel_controls?.enabled as boolean,
);

export const checkPresenterModeEnabled = createSelector(
  [getCurrentFeatureFlags],
  flags => !!flags?.presenter_mode?.enabled as boolean,
);

export const checkIsEditAssetEnabled = createSelector(
  [getCurrentFeatureFlags],
  flags => !!flags?.edit_assets?.enabled as boolean,
);

export const checkIsCreateAssetEnabled = createSelector(
  [getCurrentFeatureFlags],
  flags => !!flags?.create_assets?.enabled as boolean,
);

export const checkIsStatusUpdateEnabled = createSelector(
  [getCurrentFeatureFlags],
  flags => !!flags?.status_update?.enabled as boolean,
);

export const checkIsArchiveAssetEnabled = createSelector(
  [getCurrentFeatureFlags],
  flags => !!flags?.archive_assets?.enabled as boolean,
);

export const getBuildingTemplate = createSelector(
  [map.templates],
  templates => templates?.find(({ id }) => id === 'multi_building_site'),
);

export const getModelIdsByAssetType = createSelector(
  [map.models],
  (models) => {
    const modelIdsByAssetType = new Map<string, string[]>();
    models?.forEach((model) => {
      model.assetTypes?.forEach((assetTypeId) => {
        if (!modelIdsByAssetType.has(assetTypeId)) {
          modelIdsByAssetType.set(assetTypeId, []);
        }
        modelIdsByAssetType.get(assetTypeId)?.push(model.id);
      });
    });

    return modelIdsByAssetType;
  },
);

export const getUserManagementFlags = createSelector(
  [map.features],
  (features) => {
    const featureUserManagement = (features && features.user_management) || null;
    if (!featureUserManagement) {
      return null;
    }

    const { flags } = featureUserManagement;
    const userManagementFlags = {};
    Object.keys(flags).forEach((flagKeys) => {
      userManagementFlags[flagKeys] = flags[flagKeys].enabled;
    });
    return userManagementFlags as { [flags: string]: boolean };
  },
);

export const getAssetUpdateToolFlags = createSelector(
  [map.features],
  (features) => {
    const featureAssetUpdateTool = (features && features.asset_update_tool) || null;
    if (!featureAssetUpdateTool) {
      return null;
    }

    const { flags } = featureAssetUpdateTool;
    const assetUpdateToolFlags = {};
    Object.keys(flags).forEach((flagKeys) => {
      assetUpdateToolFlags[flagKeys] = flags[flagKeys].enabled || false;
    });
    return assetUpdateToolFlags as { [flags: string]: boolean };
  },
);

export const getStatusUpdateToolFlags = createSelector(
  [map.features],
  (features) => {
    const featureStatusUpdateTool = (features && features.status_update_tool) || null;
    if (!featureStatusUpdateTool) {
      return null;
    }

    const { flags } = featureStatusUpdateTool;
    const statusUpdateToolFlags = {};
    Object.keys(flags).forEach((flagKeys) => {
      statusUpdateToolFlags[flagKeys] = flags[flagKeys].enabled || false;
    });
    return statusUpdateToolFlags as { [flags: string]: boolean };
  },
);

export const getPowerBiEmbed = createSelector(
  [map.features],
  features => features?.powerbi?.embed,
);

export const getRoleTypes = createSelector(
  [map.roleTypes, map.managedRoles],
  (roleTypes, managedRoles) => {
    const rolesTypesKeyedById = new Map(roleTypes?.role_types.map(
      role_types => [role_types.id, role_types],
    ));
    const managedRolesTypes: RoleType[] = [];

    managedRoles?.forEach((managedRole) => {
      const roles = rolesTypesKeyedById.get(managedRole);
      if (roles) {
        managedRolesTypes.push(roles);
      }
    });

    return managedRolesTypes;
  },
);

export const getAnnouncement = createSelector(
  [map.announcement],
  announcement => announcement || null,
);

export const getIFrameUrlByAssetManagerId = createSelector(
  [map.features],
  (features): Map<string, string> => {
    const byFeature: Map<string, string> = new Map();
    if (!features) return byFeature;
    const featuresKeys = Object.keys(features);
    featuresKeys?.forEach(key => byFeature.set(key, features[key]?.url));
    return byFeature;
  },
);

export const getIFrameUrl = createSelector(
  [
    map.assetManagerId,
    getIFrameUrlByAssetManagerId,
  ],
  (
    assetManagerId,
    urlByFeature,
  ) => urlByFeature.get(assetManagerId),
);

export const getLayerTypesKeyedById = createSelector(
  [map.layerTypes],
  layerTypes => new Map(layerTypes?.map(layerType => [layerType.id, layerType])),
);

export const getDateFormat = createSelector(
  [map.dateFormat],
  dateFormat => dateFormat,
);

export const getProjectUserListKeyedById = createSelector(
  [map.projectUserList],
  projectUserList => new Map(projectUserList?.map(
    projectUserSummary => [projectUserSummary.id, projectUserSummary],
  )),
);
